import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCheckbox,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
} from "mdb-react-ui-kit";
import { CustomDialog } from "../shared/Dialog";
import { OrderTable } from "./OrderTable";
import { ToastContainer } from "react-toastify";
import { Alert } from "react-bootstrap";
import { KycStatus } from "../shared/model/KycStatus";
import { KycDialog } from "./KycDialog";
import { LoanAgreementView } from "./LoanAgreementView";
import { SanctionLetterView } from "./SanctionLetterView";
import { InlineRadioButtons } from "./views/InlineRadioButtons";

export const MainView = ({
  employee,
  onAmountChanged,
  onDaysChanged,
  submitButton,
  orders,
  dialogModel,
  requestQuoteViewModel,
  onTermsCheckBoxChanged,
  termsCheckboxChecked,
  onStartKycClicked,
  showKycDialog,
  onSubmitKycClicked,
  onCloseKycDialogClicked,
  isSanctionLetterOpen,
  isLoanAgreementOpen,
  onClickShowSanctionLetter,
  onClickShowLoanAgreement,
  onSanctionLetterClosed,
  onLoanAgreementClosed,
  onLogOutClicked,
  loanOptions,
  selectedLoanOption,
  handleLoanOptionChange,
}) => {
  return (
    <section style={{ backgroundColor: "#eee" }}>
      <div className="top-strip">
        <div class="left-text">Taru Pay</div>
        <MDBIcon color="secondary" size="sm" onClick={onLogOutClicked}>
          <img
            src="https://i.ibb.co/xX7gq0R/Logout.png"
            style={{ width: "27px" }}
            alt="logo"
          />
        </MDBIcon>
      </div>
      <MDBContainer>
        <MDBRow>
          <MDBCol className="mt-5">
            {(employee.kycStatus === KycStatus.PENDING ||
              employee.kycStatus === KycStatus.IN_PROCESS) && (
              <div className="alert-wrapper">
                <Alert color="primary" className="text-center">
                  {employee.kycStatus === KycStatus.PENDING &&
                    "KYC is mandatory to request loan"}
                  {employee.kycStatus === KycStatus.IN_PROCESS &&
                    "KYC is under process and expected to be completed in 2 working hours."}
                  {employee.kycStatus === KycStatus.PENDING && (
                    <MDBBtn
                      className="ms-4"
                      color="primary"
                      size="sm"
                      noRipple={true}
                      onClick={onStartKycClicked}
                    >
                      Start KYC
                    </MDBBtn>
                  )}
                </Alert>
              </div>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                  fluid
                />
                .<p className="text-muted mb-1">{employee.companyEmployeeId}</p>
                <p className="text-muted mb-4">{employee.companyName}</p>
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Full Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee.name}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee.phoneNumber}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>PAN</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee.pan}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Aadhar</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {employee.aadhar}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4 ps-3">
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="fs-3 text-primary me-1">Request Money</span>
                </MDBCardText>

                <div className="mb-2">
                  {" "}
                  <p>
                    <b>Remaining Eligibility: {employee.eligibleAmount}</b>
                  </p>
                </div>
                <MDBInput
                  className="mb-2"
                  label="Loan Amount"
                  id="typeNumber"
                  onChange={(e) => {
                    onAmountChanged(e);
                  }}
                  type="number"
                />

                {requestQuoteViewModel && (
                  <MDBRow className="wrap">
                    <MDBCardText className="mt-4 mb-4">
                      <span className="fs-3 text-primary me-1">Quote</span>
                    </MDBCardText>

                    {loanOptions && (
                      <MDBRow className="wrap mb-4">
                        <InlineRadioButtons
                          options={loanOptions}
                          initialOption={selectedLoanOption}
                          handleOptionChange={handleLoanOptionChange}
                        />
                      </MDBRow>
                    )}
                    <MDBTable bordered>
                      <MDBTableBody>
                        <tr>
                          <td>Amount Requested</td>
                          <td>{requestQuoteViewModel.amountRequested}</td>
                        </tr>
                        <tr>
                          <td>Loan Duration</td>
                          <td>{requestQuoteViewModel.loanDurationInDays}</td>
                        </tr>
                        {requestQuoteViewModel.installmentRows.length === 1 && (
                          <tr>
                            <td>Amount to be paid</td>
                            <td>{requestQuoteViewModel.amountToBePaidBack}</td>
                          </tr>
                        )}
                        {requestQuoteViewModel.installmentRows.length === 1 && (
                          <tr>
                            <td>Due Date</td>
                            <td>{requestQuoteViewModel.dueDate}</td>
                          </tr>
                        )}
                        {requestQuoteViewModel.installmentRows.length > 1 &&
                          requestQuoteViewModel.installmentRows.map(
                            (installment) => (
                              <tr>
                                <td>{installment.label}</td>
                                <td>{installment.value}</td>
                              </tr>
                            )
                          )}
                      </MDBTableBody>
                    </MDBTable>
                    <MDBCheckbox
                      name="flexCheck"
                      value=""
                      id="flexCheckDefault"
                      onChange={onTermsCheckBoxChanged}
                      checked={termsCheckboxChecked}
                      label=<div>
                        <p>
                          I have read and understood the{" "}
                          <a href="#" onClick={onClickShowSanctionLetter}>
                            Sanction Letter
                          </a>{" "}
                          and the{" "}
                          <a href="#" onClick={onClickShowLoanAgreement}>
                            {" "}
                            Loan Agreement
                          </a>
                          .
                        </p>
                      </div>
                    />
                  </MDBRow>
                )}

                <div className="text-center mt-4">
                  <MDBBtn
                    className="mb-4 w-50 primary center"
                    disabled={!submitButton.enable}
                    onClick={submitButton.onClick}
                    noRipple={true}
                  >
                    {submitButton.loader && (
                      <MDBSpinner
                        size="sm"
                        role="status"
                        tag="span"
                        className="me-2"
                      />
                    )}
                    {submitButton.text}
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBCardText className="mb-4">
                  <span className="fs-3 text-primary me-1">
                    Current Requests
                  </span>
                </MDBCardText>
                <OrderTable orders={orders} />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <CustomDialog dialogModel={dialogModel} />
        <ToastContainer />
        <KycDialog
          show={showKycDialog}
          onSubmitClicked={onSubmitKycClicked}
          onCloseClicked={onCloseKycDialogClicked}
        />
        <MDBRow style={{ backgroundColor: "grey" }}>
          <MDBCol col="6" className="p-0">
            <div className="d-flex flex-column  justify-content-center h-100 mb-4">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 class="mb-4">Need Help?</h4>
                <p class="small mb-0">
                  Contact us using the following details <br></br>
                  Email:{" "}
                  <a
                    href="mailto:payday@taruagency.com"
                    style={{ color: "white" }}
                  >
                    payday@taruagency.com
                  </a>{" "}
                  <br></br>
                  Mobile:{" "}
                  <a href="tel:+919319584322" style={{ color: "white" }}>
                    93195 84322
                  </a>{" "}
                  <br></br>
                  <br></br>
                  <br></br>
                  For long-term loans, please reach out to us directly using the
                  above mentioned contact details.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        {isSanctionLetterOpen && (
          <SanctionLetterView
            show={isSanctionLetterOpen}
            employee={employee}
            requestQuoteViewModel={requestQuoteViewModel}
            onSanctionLetterClosed={onSanctionLetterClosed}
          />
        )}
        {isLoanAgreementOpen && (
          <LoanAgreementView
            show={isLoanAgreementOpen}
            employee={employee}
            requestQuoteViewModel={requestQuoteViewModel}
            onLoanAgreementClosed={onLoanAgreementClosed}
          />
        )}
      </MDBContainer>
    </section>
  );
};
