import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import OrderRow from "../adminorders/OrderRow";

export const OrderTable = ({ orders }) => {
  const pendingAndExecutedOrders = orders.filter(
    (OrderRow) =>
      OrderRow.status === "pending" || OrderRow.status === "executed"
  );
  return (
    <MDBTable>
      <MDBTableHead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Order Date</th>
          <th scope="col">Amount Requested</th>
          <th scope="col">Duration</th>
          <th scope="col">Status</th>
        </tr>
      </MDBTableHead>
      {orders && (
        <MDBTableBody>
          {pendingAndExecutedOrders.map((value, index) => {
            return (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{value.createdDate}</td>
                <td>{value.amount}</td>
                <td>{value.durationInDays}</td>
                <td>{value.status}</td>
              </tr>
            );
          })}
        </MDBTableBody>
      )}
    </MDBTable>
  );
};
