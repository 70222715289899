import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { AdminOrderDetailsView } from "./AdminOrderDetailsView";
import { createUrl, post } from "../../network/AxiosInterceptor";

export const AdminOrderDetailsContainer = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    const request = {
      orderId: orderId,
    };
    post(createUrl("/api/orders/details"), request)
      .then((response) => {
        setOrderData(response.data);
      })
      .catch(function (error) {});
  };

  return <AdminOrderDetailsView orderData={orderData} />;
};
