import { Data } from "dataclass";

export class GetQuoteRequestDto {
  amountRequested: number;
  employeeId: string;
  companyId: string;
  loanDurationInDays: number;
  status?: string;
}

export class GetQuoteResponseDto extends Data {
  amountRequested: number = 0;
  loanDurationWiseDetails: LoanDurationWiseDetails[] = [];
}

export class LoanDurationWiseDetails extends Data {
  amountToBePaidBack: number = 0;
  loanDurationInDays: number = 0;
  duration: number = 0; // 1, 2 this value is number of months
  durationLabel: string = ""; // Month end, 2 months, 3 months
  loanDurationLabel: string = "";
  lastPaymentDate: string = "";
  installmments: Installment[] = [];
  quoteRefId: string = "";
  annualInterestRateInPercent: number = 0;
}

export class Installment extends Data {
  installmentNumber: number = 0;
  intstallmentNumberText: string = "";
  amount: number = 0;
  amountLabel: string = "";
  dateOfPayment: string = "";
  dateOfPaymentLabel: string = ""; // Eg. First Installment Due On
}
