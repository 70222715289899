import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";

function AdminLoginView(props) {
  const { username, password, onUsernameChange, onPasswordChange, onSubmit } =
    props;

  return (
    <MDBContainer className="my-5">
      <MDBRow className="justify-content-center">
        <MDBCol md="6">
          <div className="text-center mb-4">
            <h1 className="h1 fw-bold mb-0">Taru Pay</h1>
            <p className="lead text-muted mb-0">Admin Login</p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="grey-text">
              <MDBInput
                label="Username"
                icon="user"
                value={username}
                onChange={onUsernameChange}
              />
              <MDBInput
                label="Password"
                icon="lock"
                type="password"
                value={password}
                onChange={onPasswordChange}
              />
            </div>
            <div className="text-center">
              <MDBBtn color="primary" type="submit" noRipple={true}>
                Login
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default AdminLoginView;
