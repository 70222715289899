import { createUrl, get } from "../../../network/AxiosInterceptor";

export const logoutUseCase = async (onSuccess) => {
  get(createUrl("/api/auth/logout"))
    .then((response) => {
      console.log(response);
      onSuccess();
    })
    .catch(function (error) {
      console.log(error);
    });
};
