import { useState, useEffect } from "react";
import AdminOrdersView from "./AdminOrdersView";
import { createUrl, get } from "../../network/AxiosInterceptor";
import { updateOrderStatus } from "./usecase/UpdateOrderUsecase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderStatus } from "../shared/OrderStatus";
import { useNavigate } from "react-router-dom";
import { CompanyHolder } from "./CompanyHolder";
import { DateHolder } from "./DateHolder";
import moment from "moment";
import { fetchOrdersUseCase } from "./usecase/FetchOrdersUseCase";

export const AdminOrdersContainer = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [currentOrderFilter, setCurrentOrderFilter] = useState("all");
  const [totalPages, setTotalPages] = useState(1);
  const [companyList, setCompanyList] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DateHolder.create());

  const options = [
    { value: OrderStatus.PENDING, label: "Pending" },
    { value: OrderStatus.EXECUTED, label: "Executed" },
    { value: OrderStatus.CLOSED, label: "Closed" },
  ];
  const orderFilterOptions = [
    { value: "all", label: "All" },
    { value: OrderStatus.PENDING, label: "Pending" },
    { value: OrderStatus.EXECUTED, label: "Executed" },
    { value: OrderStatus.CLOSED, label: "Closed" },
  ];

  useEffect(() => {
    // Fetch orders from backend using pagination API
    fetchOrdersUseCase(
      currentPage,
      currentOrderFilter,
      selectedCompany,
      selectedDate,
      10
    )
      .then((response) => {
        let orders = response.data.data.docs.map(mapOrderDto);
        setOrders(orders);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((error) => console.log(error));
  }, [currentPage, currentOrderFilter, selectedCompany, selectedDate]);

  useEffect(() => {
    get(createUrl("/api/companies")).then((response) => {
      let companyNames = response.data.map(function (entry) {
        var newObj = {};
        newObj["label"] = entry["name"];
        newObj["value"] = entry["_id"];
        return newObj;
      });
      var newObj = {};
      newObj["label"] = "All";
      newObj["value"] = "All";
      setCompanyList([newObj].concat(companyNames));
      setCompanyLoading(false);
    });
  }, []);

  const onOrderIdClicked = (orderId) => {
    navigate(`/admin/orders/${orderId}`);
  };

  const onCompanySelected = (newValue, actionMeta) => {
    const companyName = newValue["label"];
    const companyId = newValue["value"];
    setSelectedCompany(
      CompanyHolder.create({ name: companyName, id: companyId })
    );
    setCurrentPage(1);
  };

  function mapOrderDto(order) {
    return {
      orderId: order.id,
      employeeName: order.employee.fullName,
      phoneNumber: order.employee.phoneNumber,
      createdAt: moment(order.createdAt).format("ll"),
      updatedAt: moment(order.updatedAt).format("ll"),
      quoteRefId: order.quoteRefId,
      companyName: order.company.name,
      amountRequested: order.amountRequested,
      amountToBePaidBack: order.amountToBePaidBack,
      status: order.status,
    };
  }

  const onChooseDateClicked = () => {
    setShowDatePicker(true);
  };

  const onDatePicked = (startDate, endDate) => {
    setShowDatePicker(false);
    const startDateAsMoment = moment(startDate);
    const endDateAsMoment = moment(endDate);
    const label =
      startDateAsMoment.format("ll") + " - " + endDateAsMoment.format("ll");
    setSelectedDate(
      DateHolder.create({
        label: label,
        startDate: startDate,
        endDate: endDate,
      })
    );
    setCurrentPage(1);
  };

  const onDatePickerClosed = () => {
    setShowDatePicker(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onStatusChange = async (orderId, status) => {
    try {
      await updateOrderStatus(orderId, status);
      toast.success("Order updated successfully.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "light",
      });
      // Reload the order list or do any other action that you want to do
    } catch (error) {
      toast.error("Something went wrong order is not updated.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  const onFilterChange = async (filter) => {
    setCurrentOrderFilter(filter);
    setCurrentPage(1);
  };

  return (
    <AdminOrdersView
      companyNames={companyList}
      companyLoading={companyLoading}
      onCompanySelected={onCompanySelected}
      orders={orders}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      onStatusChange={onStatusChange}
      options={options}
      orderFilterOptions={orderFilterOptions}
      currentOrderFilterValue={currentOrderFilter}
      onFilterChange={onFilterChange}
      onOrderIdClicked={onOrderIdClicked}
      onChooseDateClicked={onChooseDateClicked}
      showDatePicker={showDatePicker}
      onDatePicked={onDatePicked}
      onDatePickerClosed={onDatePickerClosed}
      dateLabel={selectedDate.label}
      selectedCompany={selectedCompany}
      selectedDate={selectedDate}
    />
  );
};

export default AdminOrdersContainer;
