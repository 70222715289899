import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

export const AdminEmployeeDetailsView = ({ employeedData }) => {
  if (!employeedData) {
    return <div>Loading...</div>;
  }

  const employee = employeedData;

  return (
    <MDBContainer>
      <MDBRow className="mt-4">
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <h3>Employee Information</h3>
              <hr />
              <p>
                <strong>Employee ID:</strong> {employee.employeeId}
              </p>
              <p>
                <strong>Name:</strong> {employee.name}
              </p>
              <p>
                <strong>Phone Number:</strong> {employee.phoneNumber}
              </p>
              <p>
                <strong>Pan Card:</strong> {employee.panCard}
              </p>
              <p>
                <strong>Aadhar Card:</strong> {employee.aadharCard}
              </p>
              <p>
                <strong>Kyc Status:</strong> {employee.kycStatus}
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <h3>Campany Information</h3>
              <hr />
              <p>
                <strong>Company ID:</strong> {employee.companyId}
              </p>
              <p>
                <strong>Company Name:</strong> {employee.companyName}
              </p>
              <p>
                <strong>Company Contact Person</strong>{" "}
                {employee.companyContactPerson}
              </p>
              <p>
                <strong>Contact Person Number </strong>{" "}
                {employee.companyContactPersonPhoneNumber}
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
