import React, { useState } from "react";
import Select from "react-select";

const OrderRow = ({ order, options, handleStatusChange, onOrderIdClicked }) => {
  const [status, setStatus] = useState(order.status);

  const handleChange = (selectedOption, actionMeta) => {
    setStatus(selectedOption.value);
    handleStatusChange(order.orderId, selectedOption.value);
  };

  // added so that display options are visibile and don't hide behind the table
  // along with custom styles added menuPortalTarget as well
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "2px solid #80bdff" : "1px solid #ced4da",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : null,
      "&:hover": { borderColor: state.isFocused ? "#80bdff" : "#ced4da" },
    }),
  };

  const handleClick = (event) => {
    event.preventDefault();
    onOrderIdClicked(order.orderId);
  };

  return (
    <tr>
      <td>
        <span
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {order.orderId}
        </span>
      </td>
      <td>{order.employeeName}</td>
      <td>{order.companyName}</td>
      <td>{order.amountRequested}</td>
      <td>
        <Select
          value={{ value: status, label: status }}
          options={options}
          onChange={handleChange}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
      </td>
    </tr>
  );
};

export default OrderRow;
