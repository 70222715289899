import React, { useState, useEffect } from "react";
import { createUrl, post } from "../../network/AxiosInterceptor";
import { KycApprovalView } from "./KycApprovalView";
import { Buffer } from "buffer";
import { KycStatus } from "../shared/model/KycStatus";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { updateKycStatusUseCase } from "./usecase/UpdateKycStatusUseCase";

const COLUMNS = {
  IMAGE_PROFILE_PIC: "image_profile_pic",
  IMAGE_PAN: "image_pan",
  IMAGE_AADHAR_FRONT: "image_aadhar_front",
  IMAGE_AADHAR_BACK: "image_aadhar_back",
};

export const KycApprovalContainer = () => {
  let { userId } = useParams();

  const [images, setImages] = useState([]);

  useEffect(() => {
    // Make API call to fetch images from server
    const request = {
      userId: userId,
    };
    post(createUrl("/api/image/get-user-docs"), request)
      .then((response) => {
        var imageStrings = [];
        imageStrings.push(
          getBase64ImageFromBytes(response.data[COLUMNS.IMAGE_PROFILE_PIC])
        );
        imageStrings.push(
          getBase64ImageFromBytes(response.data[COLUMNS.IMAGE_PAN])
        );
        imageStrings.push(
          getBase64ImageFromBytes(response.data[COLUMNS.IMAGE_AADHAR_FRONT])
        );
        imageStrings.push(
          getBase64ImageFromBytes(response.data[COLUMNS.IMAGE_AADHAR_BACK])
        );
        setImages(imageStrings);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const getBase64ImageFromBytes = (bytes) => {
    return Buffer.from(bytes).toString("base64");
  };

  const handleApprove = () => {
    updateUserKycStatus(KycStatus.COMPLETED);
  };

  const handleDisapprove = () => {
    updateUserKycStatus(KycStatus.PENDING);
  };

  const updateUserKycStatus = async (kycStatus) => {
    try {
      await updateKycStatusUseCase(userId, kycStatus);
      toast.success("Kyc updated successfully.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "light",
      });
    } catch (error) {
      toast.error("Something went wrong Kyc is not updated.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  return (
    <KycApprovalView
      images={images}
      handleApprove={handleApprove}
      handleDisapprove={handleDisapprove}
    />
  );
};
