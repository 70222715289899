import { useState } from "react";
import {
  createUrl,
  get,
  post,
  setStoredJwt,
} from "../../network/AxiosInterceptor";
import { useNavigate } from "react-router-dom";
import AdminLoginView from "./AdminLoginView";

export const AdminLoginContainer = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const request = {
      username: username,
      password: password,
    };

    post(createUrl("/api/auth/admin/signIn"), request)
      .then((response) => {
        setStoredJwt(response.data.accessToken);
        navigate("/admin");
      })
      .catch(function (error) {});
  };

  return (
    <AdminLoginView
      username={username}
      password={password}
      onUsernameChange={handleUsernameChange}
      onPasswordChange={handlePasswordChange}
      onSubmit={handleSubmit}
    />
  );
};

export default AdminLoginContainer;
