import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from "mdb-react-ui-kit";

const today = new Date();
const day = today.getDate();
const month = today.getMonth() + 1; // Note that months are zero-indexed, so we add 1 to get the actual month
const year = today.getFullYear();

export const SanctionLetterView = ({
  show,
  employee,
  requestQuoteViewModel,
  onSanctionLetterClosed,
}) => {
  const { name, address, phoneNumber } = employee;
  const {
    amountToBePaidBack,
    loanDurationInDays,
    dueDate,
    quoteRefId,
    annualInterestRate,
  } = requestQuoteViewModel;
  const [modalOpen, setModalOpen] = useState(show);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCrossClicked = () => {
    toggleModal();
  };

  const onOkClicked = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    onSanctionLetterClosed();
  };

  const modalBodyStyle = {
    maxHeight: "300px",
    overflowY: "auto",
  };

  return (
    <MDBModal show={show} backdrop={true}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Sanction Letter</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCrossClicked}
            />
          </MDBModalHeader>
          <MDBModalBody style={modalBodyStyle}>
            <p>
              TARU AGENCIES AND INVESTMENTS PRIVATE LIMITED
              <br />
              11/175, New Moti Nagar,
              <br />
              Delhi 110015
              <br />
              <br />
              To, Date :{day}/{month}/{year}
              <br />
              MR/MS {name}
              <br />
              Address : {address}
              <br />
              Contact Number : {phoneNumber}
              <br />
              <br />
              Ref Number :{quoteRefId}
              <br />
              <br />
              Dear Sir/Madam
              <br />
              At your request, we are pleased to advice in principle sanction of
              a Personal Loan
              <br />
              on the agreed terms and conditions as per this letter.
              <br />
              <br />
              Terms and Conditions of Loan
              <br />
              Facility Amount (Rs) Rs. {amountToBePaidBack}/-
              <br />
              Purpose / End Use Personal Need
              <br />
              Type of Interest Fixed
              <br />
              Applicable Interest rate {annualInterestRate}% per annum
              <br />
              Tenor (Days) {loanDurationInDays}
              <br />
              Repayment Rs. {amountToBePaidBack} /- towards payment of principal
              and
              <br />
              interest;
              <br />
              Re-Payment Date {dueDate}
              <br />
              Processing Fee -----NIL
              <br />
              Application Fee [Non Refundable] Nil
              <br />
              Additional Interest / Fees / Charges As per Schedule I of the Loan
              agreement
              <br />
              Stamp Charges if any
              <br />
              <br />
              Loan Sanction Letter
              <br />
              <br />
              Date: {day}/{month}/{year}
              <br />
              Customer ID:
              <br />
              <br />
              Dear Applicant
              <br />
              <br />
              We thank you for choosing, Taru Agencies and Investments Private
              Limited, as your
              <br />
              lending partner.
              <br />
              <br />
              We are pleased to inform you that with reference to your above
              transaction /<br />
              application, we have in-principal sanction you a loan facility,
              the details of which
              <br />
              are as below:
              <br />
              General terms and conditions:
              <br />
              This Sanction Letter has been issued based on the documents and
              details
              <br />
              submitted by the customer at the time of application, and in case
              of any
              <br />
              discrepancy found later, NBFC shall have a right to revoke this
              Sanction Letter.
              <br />
              Customer may need to provide certain additional details or
              documents as required
              <br />
              by the NBFC at the time of actual disbursal of the loan.
              <br />
              The actual disbursal of the sanctioned amount shall be subject to:
              -
              <br />
              Execution of the Loan agreement between the parties, which shall
              be the final,
              <br />
              binding and superseding document for all purposes for this
              relationship between
              <br />
              you and the NBFC, andcustomer providing his express consent to the
              Tenure and
              <br />
              Interest Rate offered to him at the sole discretion of the NBFC.
              <br />
              The NBFC shall have all the rights to revise the Amount
              Sanctioned, Interest Rate,
              <br />
              Processing fees or any other charges at its sole discretion
              without any prior
              <br />
              intimation to the customer.
              <br />
              The NBFC shall have all the rights to revoke the Sanction Letter
              at any time without
              <br />
              prior intimation to the customer.
              <br />
              This Sanction Letter is valid for a limited period specified above
              and customer may
              <br />
              need to undergo underwriting process at the expiry of term.
              <br />
              Final loan sanction is subject to legal, technical, and financial
              verification.
              <br />
              <br />
              Yours Sincerely
              <br />
              <br />
              Taru Agencies and Investments Private Limited
              <br />
              <br />
              (This is a computer-generated document. No signature is required.)
              <br />
              <br />
              OTP verified by {name} on {day}/{month}/{year} via Mobile No{" "}
              {phoneNumber}
              <br />
              SIGNED AND SEALED BY TARU AGENCIES AND INVESTMENTS PVT LTD{" "}
            </p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={onOkClicked} noRipple={true}>
              Okay
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
