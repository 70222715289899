import React from "react";

export const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        Terms &amp; Condition for Loan PLEASE READ THESE TERMS AND CONDITIONS
        (&quot;Terms&quot;) CAREFULLY. THESE TERMS FORM A LEGAL AGREEMENT
        BETWEEN TARU AGENCIES AND INVESTMENTS PRIVATE LIMITED AND YOU. BY
        CLICKING ON &quot;I ACCEPT&quot; OR &quot;I AGREE&quot; OR BY
        DOWNLOADING, INSTALLING OR OTHERWISE USING THE SERVICES, YOU ACCEPT AND
        AGREE TO THE TERMS CONTAINED HEREIN. IF YOU DO NOT AGREE TO THESE TERMS,
        DO NOT CLICK &quot;I ACCEPT&quot; OR &quot;I AGREE&quot; OR DOWNLOAD,
        INSTALL OR USE THE SERVICES. DEFINITIONS &quot;Website.&quot; shall mean
        File, the website created, developed and designed by the Lender for
        providing the Services. &quot;LENDER&quot; shall mean TARU AGENCIES AND
        INVESTMENTS PRIVATE LIMITED (&ldquo;Tarupay&rdquo;) which is a
        non-banking financial company incorporated under the Companies Act, 2013
        having its registered office at 11/175, New Moti Nagar, Delhi 110015,
        India and authorized to sanction loans, process and grant the Loan to
        the Customer(s), through the Platform. &quot;Customer(s)&quot; or
        &quot;you&quot; or &quot;End-Users&quot; shall mean any person who
        accesses, downloads, uses, views the Platform and the Services.
        &quot;Loan&quot; shall mean the loan that you may apply for through the
        Platform and which is sanctioned and granted by LENDER, subject to the
        applicable terms and conditions of the Loan Agreement. &quot;Loan
        Agreement&quot; shall mean the loan agreement to be executed between
        LENDER for granting the Loan whether in physical or electronic form as
        may be applicable from time-to-time. &quot;Online Stores&quot; shall
        mean Windows Store, Android Google Play, iOS App store or any other
        online store or web portal where the App/website will be made available
        by the Lender to the End-Users, from time to time. &quot;Outstanding
        Amount(s)&quot; shall mean the Loan, interests and charges due and
        payable by you to LENDER, on respective due date(s).
        &quot;Platform&quot; shall mean the App and the Website collectively.
        &quot;Services&quot; shall mean the services of granting, sanctioning,
        lending of short term loans, through the Platform by LENDER. &quot;Third
        Party Platforms&quot; shall mean social networking platforms, such as
        Facebook, LinkedIn and other similar platforms. &quot;User Data&quot;
        shall mean any data, information, documents or materials submitted with
        the Lender prior to or during the use of the Services.
        &quot;Website&quot; shall mean www.tarupay.in, managed and operated by
        the Company for the provision of Services. SERVICES Through the
        Services, you may apply for the Loan, subject to the fulfilment of the
        eligibility criteria laid down in the Website/app. You understand that
        the Lender may collect, authenticate, track your location, verify and
        confirm the User Data, documents and details in its discretion as may be
        required by LENDER to sanction the Loan. LENDER may collect and store
        the User Data through the Website/app available on the Platform. In
        order to avail the Services, you are required to register with the
        Lender by logging in through Website/app provided by lender (&quot;User
        Account&quot;). During the Application process, you shall be required to
        share and upload the User Data on the Website/Mobile Application Form.
        User Data shall include personal information including but not limited
        to your name, e-mail address, gender, date of birth, mobile number,
        passwords, photograph, mobile phone information, SMS and browsing
        history, data and login-in credentials of Third Party Platforms,
        financial information such as bank documents, salary slips, bank
        statements, PAN card, bank account no., data from Credit Information
        Companies, data required for Know Your Customer compliances, requirement
        and other relevant details (&quot;Personal Information&quot;). You agree
        that the Personal Information shall always be accurate, correct and
        complete. As part of the Services, you authorize us to import your
        details and Personal Information dispersed over Third Party Platforms.
        You understand and acknowledge that we may periodically request for
        updates on such Personal Information and we may receive such updated
        information from Third Party Platforms.
      </p>

      <p>
        We collect, store, and use the data stored or accessed through the
        Central KYC Registry for completing Know Your Customer process from you
        in accordance with the Prevention of Anti-Money Laundering Act 2002 and
        KYC Master Direction 2016. Such collected data shall be used to
        ascertain your compliance with the specified regulations through various
        modes but not limited to Central KYC Registry, UIDAI and other Know Your
        Customer Authentication method, hence you hereby expressly out of your
        own free will and without any undue influence, coercion, fraud, mistake,
        misrepresentation provide your consent to Lender to use, access, modify,
        store, download, upload or take any other action in relation to any data
        stored or accessed through the Central KYC Registry for the same. All
        transactions undertaken on your behalf by the Lender will be on the
        basis of your express instructions/consent and will be strictly on a
        non-discretionary basis. You also authorise the Lender to get your
        credit information report from one or more Credit Information Companies
        as decided by the Company from time to time. Once you verify and upload
        the User Data and/or other documents and details in the website/Mobile
        Application Form, the Lender shall process the same. Upon the completion
        of the document verification by the Lender, the Loan may be sanctioned
        by LENDER to you, subject to fitting eligibility criteria and other
        conditions set forth by LENDER for sanctioning the Loan. Thereafter, you
        are required to fill and upload the ECS/NACH mandate form/Cheque or any
        other document as may be required by LENDER . The Company may collect
        the physical documents including signatures on those documents required
        for sanctioning and processing the Loan. Upon the collection of
        documents LENDER shall disburse the Loan subject to the terms and
        conditions of the Loan Agreement. The sanctioned Loan shall be disbursed
        as per the mode provided in the Website/Mobile Application Form. You are
        required to repay the Outstanding Amount(s) to LENDER, on the respective
        due date(s) mentioned in the Website/Mobile Application Form. You
        understand and acknowledge that the Lender reserves the right to track
        your location (&quot;Track&quot;) during the provision of Services, and
        also in the event that you stop, cease, discontinue to use or avail the
        Services, through deletion or uninstallation of App/Website or
        otherwise, till the event that your obligations to pay the Outstanding
        Amount(s) to LENDER exist. Deletion, uninstallation, discontinuation of
        our Services, shall not release you from the responsibility, obligation
        and liability to repay the Outstanding Amount(s). You understand and
        acknowledge that you shall be solely responsible for all the activities
        that occur under your User Account while availing the Services. You
        undertake that the Lender shall not be responsible and liable for any
        claims, damages, disputes arising out of use or misuse of the Services.
        By usage of the Services, you shall be solely responsible for
        maintaining the confidentiality of the User Account and for all other
        related activities under your User Account. The Lender reserves the
        right to accept or reject your registration for the Services without
        obligation of explanation. You understand and acknowledge that, you are
        solely responsible for the capability of the electronic devices and the
        internet connection, you chose to run the Platform. The Platform&rsquo;s
        operation or the Services on your electronic device is subject to
        availability of hardware, software specifications, internet connection
        and other features and specifications, required from time to time. The
        User Data provided during the registration is stored by the Lender for
        your convenience. You are not required to log-in to your User Account,
        every time, to use or access the Platform. You understand and
        acknowledge that by accepting these Terms, you authorize us to Track,
        fetch and use the User Data, including but not limited to your Personal
        Information, for the purpose of authentication and any updates with
        regards to your credentials. I/We hereby provide my/our consent and
        hereby authorize the Lending/NBFC and its employee, agent, associate to
        contact me/us through telephonic calls, or SMS on my mobile number or
        through any other communication mode, to provide me/us transactional
        information, various loan offer schemes or loan promotional schemes or
        any other promotional schemes. LICENSE License to use the Website/app:
        In order to use the Services, you are required to download/search the
        app/Website. For this purpose, you represent and warrant that you are of
        the age of majority as per the applicable laws to which you are subject
        to and are competent to understand, enter into, and comply with these
        Terms. The Company grants you a limited, non-exclusive,
        non-transferable, non-sub-licensable and revocable right to use the
        app/website. The App/Website is licensed and not sold to you and shall
        only be used as per these Terms. Scope of License: You may install,
        download, access, search or use the App/website through the Online
        Stores on/from mobile phones, tablets or any other electronic devices.
        Maintenance &amp; Support: You acknowledge that while the Lender may, at
        its sole discretion, provide maintenance and support for the app/Website
        from time to time, the Lender shall have no specific obligation
        whatsoever to furnish such services to you. Updates/Upgrades: We may
        launch new updates/upgrades for the app/Website, you may subscribe to
        the same through the Online Stores. In the event, you choose not to
        update/upgrade the App/website, certain features or functionality shall
        not be accessible to you. RESTRICTIONS: You agree not to: use the
        Platform or the Services for committing fraud, embezzlement, money
        laundering, or for any unlawful and/or illegal purposes; to reproduce,
        duplicate, copy, sell, resell or exploit any portion of the App/website;
        upload, post, email, transmit or otherwise make available any content
        that is unlawful, harmful, threatening, abusive, harassing, torturous,
        defamatory, vulgar, obscene, libelous, invasive of another&rsquo;s
        privacy, hateful, or racially, ethnically or otherwise objectionable
        through the Platform; use the Platform to harm or injure any third
        party; impersonate any person or entity, on the Platform; forge headers
        or otherwise manipulate identifiers in order to disguise the origin of
        any content transmitted through the App/website; upload, post, email,
        transmit or otherwise make available any content that you do not have a
        right to make available under any law or under contractual or fiduciary
        relationships (such as inside information, proprietary and confidential
        information learned or disclosed as part of employment relationships or
        under nondisclosure agreements); upload, post, email, transmit or
        otherwise make available on the Platform, any content that infringes any
        patent, trademark, trade secret, copyright or other proprietary rights
        of any party; upload, post, email, transmit or otherwise make available
        on the Platform, any unsolicited or unauthorized advertising,
        promotional materials, &quot;junk mail,&quot; &quot;spam&quot;,
        &quot;chain letters,&quot; &quot;pyramid schemes,&quot; or any other
        form of solicitation; upload, post, email, transmit or otherwise make
        available on the Platform, any material that contains software viruses
        or any other computer code, files or programs designed to interrupt,
        destroy or limit the functionality of any computer software or hardware
        or telecommunications equipment; disrupt the normal flow of or otherwise
        act in a manner that negatively affects other user&rsquo;s ability to
        engage in real time exchanges; interfere with or disrupt the Platform or
        servers or networks connected to the Platform, or disobey any
        requirements, procedures, policies or regulations of networks connected
        to the Platform; intentionally or unintentionally violate any applicable
        local, state, national or international laws and any regulations having
        the force of law. CONTENT AVAILABLE: You acknowledge that the Lender
        makes no representations or warranties about the material, data, and
        information, such as data files, text, facts and figures, computer
        software, code, audio files or other sounds, photographs, videos, or
        other images (collectively, the &quot;Content&quot;) which you may have
        access to as part of the Services, or through your use of the Platform.
        Under no circumstances, shall the Lender be liable in any way for any
        Content, including, but not limited to any infringing Content, any
        errors or omissions in Content, or for any loss or damage of any kind
        incurred as a result of the use of any Content posted, transmitted,
        linked from, or otherwise accessible through or made available via the
        Platform. The Content on the Platform should not be regarded as an
        offer, solicitation, invitation, advice or recommendation to buy or sell
        investments, securities or any other instrument or financial products /
        schemes of the Lender, unless expressly covered in these Terms.
        PROPRIETARY RIGHTS OF THE LENDER: You understand, acknowledge and agree
        that the Lender is the sole owner of all rights, title and interest,
        including any and all intellectual property rights in the Content,
        Platform, Services, logos, trade names, brand names, designs and any
        necessary software used in connection with the Platform. There may be
        proprietary logos, service marks and trademarks found on the Platform
        whether owned/used by the Lender or otherwise. By displaying them on the
        Platform, the Lender is not granting you any license to utilize the
        proprietary logos, service marks, or trademarks. Any unauthorized use of
        the same may violate applicable intellectual property laws.
      </p>

      <p>
        You understand and acknowledge that the Platform is owned by the Lender.
        Nothing under these Terms shall be deemed to be a transfer in ownership,
        rights, title, from the Lender to you or any third party, in the
        Platform. You are entitled to avail the Services offered by the Lender
        during the validity of your registration with the Lender. LINKS TO THIRD
        PARTY SITES: The Platform may contain links to other websites owned and
        operated by third parties who are not related to the Platform
        (&quot;Linked Websites&quot;). The Linked Websites are not under the
        control of the Lender and the Lender shall not be responsible for the
        content of any Linked Websites or any hyperlink contained in a Linked
        Website and makes no representation or warranty with respect to the
        content of any such third party sites. The Platform provides these links
        to you as a convenience only and the inclusion of any link does not
        imply any endorsement of the Linked Website by the Lender. Your access
        or use of such Linked Website is entirely at your own risk. The Lender
        shall not be a party to any transaction between you and the Linked
        Website. Your use of a Linked Website is subject to these terms and
        conditions of that respective Linked Website. The Platform may also
        contain third party advertisements, if any. The display of such
        advertisements does not in any way imply an endorsement or
        recommendation by/of the relevant advertiser, its products or services.
        You shall independently refer to the relevant advertiser for all
        information regarding the advertisement and its products and/or
        services. The Lender accepts no responsibility for any interaction
        between you and the relevant third party and is released from any
        liability arising out of or in any way connected with such interaction.
        ANCILLARY SERVICES: You may get access to chat rooms, blogs, feedbacks,
        reviews and other features (&quot;Ancillary Services&quot;) that are/may
        be offered from time to time on the Platform and may be operated by us
        or by a third party on our behalf. You shall not (nor cause any third
        party to) use these Ancillary Services to perform any illegal activities
        (including without limitation defaming, abusing, harassing, stalking,
        threatening, promoting racism, or otherwise violating the legal rights,
        such as rights of privacy, of others) or immoral activities, falsely
        stating or otherwise misrepresenting your affiliation with a person or
        entity. Additionally, the Platform may contain advice/opinions and
        statements of various professionals/ experts/ analysts, etc. the Lender
        does not endorse the accuracy, reliability of any such advices/opinions/
        and statements. You may rely on these, at your sole risk and cost. You
        shall be responsible for independently verifying and evaluating the
        accuracy, completeness, reliability and usefulness of any opinions,
        services, statements or other information provided on the Platform. All
        information or details provided on the Platform shall not be interpreted
        or relied upon as legal, accounting, tax, financial, investment or other
        professional advice, or as advice on specific facts or matters. The
        Lender may, at its discretion, update, edit, alter and/or remove any
        information in whole or in part that may be available on the Platform
        and shall not be responsible or liable for any subsequent action or
        claim, resulting in any loss, damage and or liability. Nothing contained
        herein is to be construed as a recommendation to use any product or
        process, and the Lender makes no representation or warranty, express or
        implied that, the use thereof will not infringe any patent, or
        otherwise. TERMINATION: The Lender reserves its rights to terminate
        these Terms in the event: you breach any provision of these Terms; the
        Lender is required to do so under law; or the Lender chooses to
        discontinue the Services being offered or discontinue to operate the
        Platform; the license granted to use the App/website expires; of
        non-payment of Outstanding Amount(s). The Lender reserves its right to
        Track you, even when you have uninstalled/block the App/website or even
        after termination of these Terms, until all your obligations, including
        but not limited to payment of the Outstanding Amount(s) is in
        subsistence. Upon termination of these Terms, the rights and licenses
        granted to you under these Terms shall cease to exist, and you must
        forthwith stop using the Platform and the Services and repay the
        Outstanding Amount(s). Notwithstanding anything contained in these Terms
        or otherwise, the termination of these Terms for any reason whatsoever,
        shall not affect your obligations, including but not limited to
        repayment of the Outstanding Amount(s). DISCLAIMER OF WARRANTIES: You
        expressly understand and agree that: your use of the Services and the
        Platform is at your sole risk. The Services and the Platform are
        provided on an &quot;as is&quot; and &quot;as available&quot; basis. The
        Lender expressly disclaims all warranties of any kind, whether express
        or implied, including, but not limited to the implied warranties of
        merchantability, fitness for a particular purpose and non-infringement.
        any material downloaded or otherwise obtained through the access or use
        of the Platform, is at your own discretion and risk and that you will be
        solely responsible for any damage to your computer system, electronic
        data or loss of data that results from the download of any such
        material. no advice or information, whether verbal or written, obtained
        by you from the Company, for the Services or through the Platform shall
        create any warranty not expressly stated in these Terms. The Services
        are intended for personal, non-commercial use. You shall be solely
        responsible for the use, misuse, improper usage of the Services and the
        Platform. The Lender shall not be liable for any damages accruing out of
        the use of the Services which have not been expressly stipulated under
        these Terms. the Lender makes no warranty, including implied warranty,
        and expressly disclaims any obligation, that: (a) the Contents are and
        will be complete, Exhaustive, accurate or suitable to your requirements;
        (b) The Platform or the Services will meet your requirements or will be
        available on an uninterrupted, timely, secure, or error-free basis; (c)
        the results that may be obtained from the use of the Platform or
        Services will be accurate or reliable. INDEMNITY: You agree to indemnify
        and hold the Lender, and its subsidiaries, affiliates, officers, agents,
        co-branders or other partners, and employees, harmless from any claim or
        demand, including attorneys&rsquo; fees, made by any third party due to
        or arising out of (i) your violation of these Terms; (ii) your violation
        of any rights of other users of the Platform; (iii) your use or misuse
        of the Platform or the Services; (iv) your violation of applicable laws.
        LIMITATIONS OF LIABILITY You expressly understand and agree that the
        Lender, including its directors, officers, employees, representatives or
        the service provider, shall not be liable for any direct, indirect,
        incidental, special, consequential or exemplary damages, including but
        not limited to, damages for loss of profits, goodwill, use, data or
        other intangible losses (even if the Lender has been advised of the
        possibility of such damages), resulting from; (a) use or the inability
        to avail the Services (b) inability to use the Platform (c) failure or
        delay in providing the Services or access to the Platform (d) any
        performance or non-performance by the Lender (e) any damages to or
        viruses that may infect your electronic devices or other property as the
        result of your access to the Platform or your downloading of any content
        from the Platform and (f) server failure or otherwise or in any way
        relating to the Services. FORCE MAJEURE: Without limiting the foregoing,
        under no circumstances shall the Lender be held liable for any damage,
        loss, loss of services of Platform, due to deficiency in provision of
        the Services resulting directly or indirectly from acts of nature,
        forces, or causes beyond its reasonable control, including, without
        limitation, internet failures, computer equipment failures,
        telecommunication equipment failures, change in applicable regulations,
        including Reserve Bank of India regulations, or any other government
        regulations, floods, storms, electrical failure, civil disturbances,
        riots. PRIVACY POLICY: The Personal Information
        collected/shared/uploaded for the provision of Services has been
        exhaustively covered in our privacy policy (&quot;Privacy Policy&quot;).
        Our Privacy Policy is available here. CHANGE: The Lender reserves the
        right to modify, change, substitute, remove, suspend or update these
        Terms or any information thereof at any time by posting the updated
        Terms on the Platform. Such changes shall be effective immediately upon
        such posting. Continued use of the Services or the Platform, subsequent
        to making the changes, shall be deemed to be your acceptance of the
        revised Terms. CHOICE OF LAW AND JURISDICTION: This Agreement shall be
        construed and governed by the laws of India without regard to principles
        of conflict of laws. Parties further agree that the courts in Delhi,
        India shall have an exclusive jurisdiction over such disputes.
      </p>

      <p>
        MISCELLANEOUS: Entire Understanding: These Terms, along with the Loan
        Agreement, Website/Mobile Application Form and Privacy Policy,
        constitute the entire understanding between you and the Company with
        relation to the Services. Waiver: The failure of the Lender to exercise
        or enforce any right or provision of these Terms shall not constitute a
        waiver of such right or provision. You agree that regardless of any
        statute or law to the contrary, any claim or cause of action arising out
        of or related to availing of the Services or these Terms must be filed
        within one (1) year after such claim or cause of action arose or be
        forever barred. Severability: If any provision of these Terms is found
        by a court of competent jurisdiction to be invalid, the Parties
        nevertheless agree that the court should endeavour to give effect to the
        Parties&rsquo; intentions as reflected in the provision, and the other
        provisions of these Terms shall remain in full force and effect.
        REFERRAL PROGRAM: Referrer being the person whose referral code has been
        used and referral recipient being the person who uses Tarupay&rsquo;s
        lending facility from a valid referral code. Participant shall include,
        whether individually or collectively, both the referrer and the referral
        recipient. Referral program is available for limited cities and for
        limited time period as prescribed by Tarupay. Creating multiple
        Customer&rsquo;s accounts using multiple address, emails or signing in
        from multiple devices in order to unduly gain Referral Discount shall be
        considered to be in contravention of these Terms &amp; Conditions.
        Referral discount is non-transferable. Tarupay reserves the right to
        discontinue, add, alter, withdraw, modify, any of the terms and
        conditions of referral discount program without assigning any reasons.
        In case of any dispute in relation to referral program, Tarupay&rsquo;s
        decision shall be binding on all customers. Any taxes, cess, liabilities
        or charges payable under the program to the government or any other such
        authority or body, if any, shall be borne directly by the Customer.
        Lender reserves the right to review and investigate all referral
        activities and to suspend accounts or revoke / remove referral discount
        and withhold features or benefits obtained through Lender referral
        program. Customer assumes all liability for any injury or damage caused
        or claimed to be caused by his or her participation in this program
        and/or the acceptance and/or use of any prize, and releases the Lender,
        and their respective subsidiaries, affiliates, officers, directors,
        agents, and employees, from any such liability. Lender, and their
        respective subsidiaries, affiliates, officers, directors, agents and
        employees are not responsible for technical, hardware, software, or
        telephone malfunctions of any kind, lost or unavailable network
        connections, or failed incorrect, incomplete, inaccurate, garbled or
        delayed electronic communications caused by the user or by any of the
        equipment or programming associated with or utilized or by any human
        error which may occur in the processing of the entries in this program.
        Entry constitutes permission (except where prohibited by law) to use
        winner's name, city, state, likeness and/or voice for purposes of
        advertising, promotion and publicity without additional compensation.
        PROMOTIONAL CODE: When redeeming a promotional code advertised on our
        website, please click the applicable link to read specific promotion
        details, if mentioned. These terms and conditions apply to those
        promotions where you must redeem a promotional code as part of the
        offer. The promotional code is valid for a limited time only. Lender
        reserves the right to modify or cancel it at any time. Each promotional
        code can be used only once, unless otherwise specified. Each promotional
        code applies only to qualifying items. Details are specified in the
        promotion. The promotional code does not apply to voucher purchases. The
        promotion is subject to all restrictions set forth in the offer. The
        promotional code is not transferable, may not be resold or redeemed for
        cash. If the Customer defaults on the any of his/her EMI then the
        Customer shall not be eligible for such this promotional code discount.
        Only one promotional code can be used each time and therefore cannot be
        combined with other offers using promotional code. Each Customer shall
        be eligible for only one promotional code. Lender reserves the right to
        void the transactions where prohibited. If you violate any of the Loan
        Agreement/Master T&amp;C, the promotion will be invalid, and the
        promotional code discount will not apply. Lender reserves the right to
        update these terms and conditions without prior notice. VIOLATIONS:
        Please report any violations or grievances with relation to these Terms
        to the Company at payday@taruagency.com. Disclaimer: These Terms
        outlines the use of App/website, Services and its use on End User's
        devices and Company shall not be liable or any direct or indirect loss
        or damage to personal applications or data, due to installation, use of
        App/Website or Website/App crashes
      </p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p></p>

      <p>
        Video KYC Terms &amp; Conditions (Through Web cam &ndash; face photo) 1.
        I hereby state that I agree to authenticate myself with video-based
        customer identification process (&ldquo;V-CIP&rdquo;). Further, I
        expressly consent to and authorize the Taru Agencies and Investments
        Private Limited hereinafter referred to as Lender)(whether acting by
        itself or through any of its service providers, and whether in automated
        manner or otherwise), to do and undertake any of the following, in
        relation to my application details including my photograph, personal
        data and sensitive information about me, information, papers and data
        relating to know your customer (KYC), credit information, and any other
        information whether about me or not as may be deemed relevant by the
        Lender (collectively, &ldquo;Information&rdquo;) for the purposes of
        (&ldquo;Purposes&rdquo;) V-CIP: a. to use devices and/or software,
        including the website/app, to record and capture my Information,
        interactions inclusive of video and which will be used for the
        Information verification and Purposes; b. to collect the Information
        from me and other physical or online sources including accessing the
        same from credit information companies, information utilities, websites,
        data bases and online platforms (whether public or not); to get the
        authenticity, correctness, adequacy, etc. of the Information verified
        from any sources and persons including from online data bases; and to
        act for and on my behalf for such accessing, collecting or verifying of
        the Information including using my log in and password credentials on
        the online platforms(which shall be not used by Lender other than for
        the Purpose); such collection, access and verification may be done
        without any notice to me; c. process Information including by way of
        storing, structuring, organizing, reproducing, copying, using,
        profiling, etc. as may be deemed fit by the Lender; d. to store the
        Information for such period as may be required for contract, by law or
        for the Lender&rsquo; s evidential and claims purposes, whichever is
        longer; e. to share and disclose the Information to service providers,
        consultants, credit information companies, information utilities, other
        Lender and financial institutions, affiliates, subsidiaries, regulators,
        investigating agencies, judicial, quasi-judicial and statutory
        authorities, or to other persons as may be necessary in connection with
        the contractual or legal requirements or in the legitimate interests of
        the Lender or as per the consent; f. any of the aforesaid may be
        exercised by the Lender for the purposes mentioned above, for the
        purposes of credit appraisal, fraud detection, anti-money laundering
        obligations, for entering into contract, for direct marketing, for
        developing credit scoring models and business strategies, for
        monitoring, for evaluating and improving the quality of services and
        products, for other legitimate purposes or for any purposes with
        consent. 2. I hereby state that I have no objection in authenticating
        myself with Aadhaar based authentication system and consent to providing
        my Aadhaar Number and/or (One Time Pin) OTP data (and/or any similar
        authentication mechanism) for Aadhaar Based authentication for the
        purposes of availing of the Service from Lender. For the purpose of
        Aadhaar Based authentication, I have already read and agreed to the
        Aadhar KYC terms and conditions provided by Lender. The same is again
        confirmed by me once again T&C. I submit my Aadhaar number and
        voluntarily give my consent to: a. I expressly agree to and subject me
        to the automated processing, automated profiling and to the automated
        decision making by or on behalf of the Lender. b. I expressly agree to
        the Lender and/or its affiliates for using the Information and for
        cross-selling to me their various products and services from time to
        time. c. I hereby confirm that I have read and I hereby accept the
        Privacy Policy of Lender available at T&C. 3. I understand and agree
        that: a. During the V-CIP process, I have all the rights to disconnect
        the process if I feel discomfort in sharing any information or due to
        any of act of the V-CIP agent of Lender. b. Lender shall not be
        responsible for any discontinuance of V-CIP process due to any issue
        arising from my side, including but not limited to internet outage,
        hardware failure etc. c. Completion of this V-CIP shall not directly
        make me eligible for any Loan and shall not guarantee disbursal of any
        loan from Lender. d. My V-CIP is subject to Audit by the Authorised
        Officials of Lender and Lender has right to reject the V-CIP without
        assigning any reason thereto and can ask me to re-do V-CIP. 4. I hereby
        declare that all Information that shall be furnished by me shall be
        true, correct and complete. I will not hold Lender or any of their
        officials responsible in case of any incorrect information provided by
        me. KYC can be done thorugh any of the 3 options :- 1. Through Digi
        locker &ndash; share PAN &amp; Aadhar card 2. OTP verified linked
        &ndash; Aadhar Card 3. Upload PAN and aadhar card :- Original Photo to
        be show to the web cam (not copy)
      </p>
    </div>
  );
};
