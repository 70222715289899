import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { DateRangePicker } from "react-date-range";
import { subDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const DatePickerModalView = ({
  show,
  onDatePicked,
  onDatePickerClosed,
}) => {
  const [modalOpen, setModalOpen] = useState(show);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCrossClicked = () => {
    toggleModal();
    onDatePickerClosed();
  };

  const onOkClicked = () => {
    toggleModal();
    onDatePicked(state[0].startDate, state[0].endDate);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const modalBodyStyle = {
    maxHeight: "300px",
    overflowY: "auto",
  };

  return (
    <MDBModal show={show} backdrop={true}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Pick Date</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCrossClicked}
            />
          </MDBModalHeader>
          <MDBModalBody style={modalBodyStyle}>
            <DateRangePicker
              onChange={(item) => setState([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={onOkClicked} noRipple={true}>
              Okay
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
