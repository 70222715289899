import React, { useState, useEffect } from "react";

export const InlineRadioButtons = ({
  options,
  initialOption,
  handleOptionChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(initialOption);

  useEffect(() => {
    setSelectedOption(initialOption);
  }, [initialOption]);

  const onOptionChange = (option) => {
    setSelectedOption(option);
    handleOptionChange(option);
  };

  return (
    <div>
      {options.map((option) => (
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            value={option.label}
            checked={selectedOption.id === option.id}
            onChange={() => onOptionChange(option)}
            id={option.id}
          />
          <label class="form-check-label" for={option.id}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
