import React, { useEffect, useRef, useState } from "react";
import { HomeView, LoginMode } from "./HomeView";
import { DialogButton, DialogModel } from "../shared/DialogModel";
import { ButtonState } from "../shared/ButtonState";
import {
  createUrl,
  get,
  post,
  setStoredJwt,
} from "../../network/AxiosInterceptor";
import { useNavigate } from "react-router-dom";

export const HomeContainer = () => {
  const navigate = useNavigate();

  const otpRef = useRef("");
  const employeeIdRef = useRef("");
  const companyIdRef = useRef("");
  const phoneNumberRef = useRef("");

  let dialogModel = new DialogModel();
  dialogModel.show = false;
  const [isSignInDisabled, setSignInDisabled] = useState(true);
  const [dialog, setDialog] = useState(dialogModel);
  const [showSignInLoader, setShowSignInLoader] = useState(false);
  const [showOtpInputField, setShowOtpInputField] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [loginMode, setLoginMode] = useState(LoginMode.NONE);
  let otpButton = new ButtonState();
  otpButton.visible = false;
  const [otpButtonState, setOtpButtonState] = useState(otpButton);

  useEffect(() => {
    get(createUrl("/api/companies")).then((response) => {
      let companyNames = response.data.map(function (entry) {
        var newObj = {};
        newObj["label"] = entry["name"];
        newObj["value"] = entry["_id"];
        return newObj;
      });
      setCompanyList(companyNames);
      setLoading(false);
    });
  }, []);

  const onCompanySelected = (newValue, actionMeta) => {
    companyIdRef.current = newValue["value"];
    if (newValue == null) {
      setLoginMode(LoginMode.NONE);
    } else {
      setLoginMode(LoginMode.PHONE_NUMBER);
    }
  };

  const onEmployeeIdChange = (event) => {
    // 👇 Get input value from "event"
    const empId = event.target.value;
    employeeIdRef.current = empId;
    setSignInDisabled(empId.length === 0);
  };

  const onPhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    phoneNumberRef.current = phoneNumber;
    setSignInDisabled(phoneNumber.length != 10);
  };

  const onOtpChanged = (event) => {
    const otpInput = event.target.value;
    let btnState = Object.assign({}, otpButtonState);
    if (otpInput.length > 3) {
      btnState.enable = true;
    }
    setOtpButtonState(btnState);
    otpRef.current = otpInput;
    btnState.onClick = onOtpSubmitted;
  };

  const onOtpSubmitted = () => {
    let btnState = Object.assign({}, otpButtonState);
    btnState.enable = false;
    btnState.loader = true;
    setOtpButtonState(btnState);
    const request = {
      employeedId: employeeIdRef.current,
      phoneNumber: phoneNumberRef.current,
      companyId: companyIdRef.current,
      otp: otpRef.current,
    };
    post(createUrl("/api/auth/signIn"), request)
      .then((response) => {
        setStoredJwt(response.data.accessToken);
        navigate("/me");
      })
      .catch(function (error) {
        let btnState = Object.assign({}, otpButtonState);
        btnState.visible = true;
        btnState.enable = true;
        btnState.loader = false;
        setOtpButtonState(btnState);
        if (error.response.data) {
          handleError(error);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  function handleError(error) {
    let dialogModel = new DialogModel();
    dialogModel.show = true;
    dialogModel.title = "Authentication error";
    dialogModel.body = error.response.data["error_message"];
    let positiveBtn = new DialogButton();
    positiveBtn.label = "Dismiss";
    positiveBtn.onClick = () => {
      let closeModel = new DialogModel();
      closeModel.show = false;
      setDialog(closeModel);
    };
    dialogModel.positiveBtn = positiveBtn;
    dialogModel.onCrossClick = () => {
      let closeModel = new DialogModel();
      closeModel.show = false;
      setDialog(closeModel);
    };
    setDialog(dialogModel);
  }

  const onGetOtpClicked = () => {
    setShowSignInLoader(true);
    setSignInDisabled(true);
    const request = {
      employeedId: employeeIdRef.current,
      phoneNumber: phoneNumberRef.current,
      companyId: companyIdRef.current,
    };
    post(createUrl("/api/auth/fetchOtp"), request)
      .then((response) => {
        let status = response.data["status"];
        if (status === "success") {
          setShowOtpInputField(true);
          let otpButtonState = new ButtonState();
          otpButtonState.visible = true;
          otpButtonState.enable = false;
          otpButtonState.text = "Submit Otp";
          otpButtonState.onClick = onOtpSubmitted;
          setOtpButtonState(otpButtonState);
        } else {
          // handle error, error could be service is down
        }
        setSignInDisabled(false);
      })
      .catch(function (error) {
        setSignInDisabled(false);
        setShowSignInLoader(false);
        if (error.response.data) {
          handleError(error);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  return (
    <div>
      <HomeView
        companyNames={companyList}
        isLoading={loading}
        loginMode={loginMode}
        onCompanySelected={onCompanySelected}
        onGetOtpClicked={onGetOtpClicked}
        showSignInLoader={showSignInLoader}
        isSignInDisabled={isSignInDisabled}
        onEmployeeIdChange={onEmployeeIdChange}
        onPhoneNumberChange={onPhoneNumberChange}
        dialogModel={dialog}
        showOtpInputField={showOtpInputField}
        onOtpChanged={onOtpChanged}
        otpButtonState={otpButtonState}
      />
    </div>
  );
};
