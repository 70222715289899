import axios from "axios";

// const localApiUrl = "http://localhost:4000/api";
// const apiUrl = process.env.API_URL ? process.env.API_URL : localApiUrl;

// const apiUrl = "http://localhost:4000";
const apiUrl = "https://api.tarupay.in";
const jwtKey = "accessToken";

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];
    const accessToken = localStorage.getItem(jwtKey);
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  const moment = require("moment-timezone");
  const timezone = moment.tz.guess();
  config.headers["Timezone"] = timezone;
  return config;
});

export const createUrl = (endpoint) => new URL(endpoint, apiUrl).href;
export const isStoredJwt = () => Boolean(localStorage.getItem(jwtKey));
export const setStoredJwt = (accessToken) =>
  localStorage.setItem(jwtKey, accessToken);

export const get = axios.get;
export const patch = axios.patch;
export const put = axios.put;
export const post = axios.post;
