import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from "mdb-react-ui-kit";

const today = new Date();
const day = today.getDate();
const month = today.getMonth() + 1; // Note that months are zero-indexed, so we add 1 to get the actual month
const year = today.getFullYear();

export const LoanAgreementView = ({
  show,
  employee,
  requestQuoteViewModel,
  onLoanAgreementClosed,
}) => {
  const { name, address, phoneNumber } = employee;
  const {
    amountRequested,
    amountToBePaidBack,
    loanDurationInDays,
    dueDate,
    annualInterestRate,
  } = requestQuoteViewModel;
  const [modalOpen, setModalOpen] = useState(show);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCrossClicked = () => {
    toggleModal();
  };

  const onOkClicked = () => {
    toggleModal();
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    onLoanAgreementClosed();
  };

  const modalBodyStyle = {
    maxHeight: "300px",
    overflowY: "auto",
  };

  return (
    <MDBModal show={show} backdrop={true}>
      <MDBModalDialog size="lg">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Loan Agreement</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCrossClicked}
            />
          </MDBModalHeader>
          <MDBModalBody style={modalBodyStyle}>
            <p>
              <h2>
                Loan Agreement:- to be accepted while accepting the loan amount
                (final stage)
              </h2>{" "}
              Date : {day}/{month}/{year}
            </p>
            <p>
              {" "}
              LOAN AGREEMENT This Loan Agreement (&quot;Agreement&quot;) has
              been made at Delhi and on the date as mentioned in Annexure 1
              hereto, by and between: TARU AGENCIES AND INVESTMENTS PRIVATE
              LIMITED which is a non-banking financial company incorporated
              under the Companies Act, 1956 having its registered office at
              11/175, New Moti Nagar, Delhi 110015, (hereinafter referred to as
              &ldquo;Lender&rdquo;, which expression shall, unless it be
              repugnant to the context or meaning thereof, include its
              successors and permitted assigns) of the first part; AND The
              Borrower as detailed in Annexure 1, hereinafter referred to as the
              &quot;Borrower&quot;,(which expression shall, unless it be
              repugnant to the context or meaning thereof, include its
              successors and permitted assigns) of the second part. The Lender,
              the Borrower shall hereinafter individually be referred to as a
              &ldquo;Party&rdquo; and collectively as &ldquo;Parties&rdquo;. NOW
              THIS AGREEMENT WITNESSETH AS FOLLOWS 1. SCOPE 1.1. The Lender is a
              Non &ndash; Banking Financial Company engaged in the business of
              providing loans to its customers. 1.2. The Borrower had approached
              the Lender for Loan not exceedingof Rs. {amountRequested}/- (the
              loan amount hereinafter shall be referred to as &lsquo;the
              loan&rsquo; or &ldquo;Loan&rdquo; or &ldquo;Loan Amount&rdquo;) on
              the terms, conditions and the purpose as stated / contained in
              this Agreement. 1.3. Borrower understands that every Loan request
              shall be assessed individually, at the Lender&rsquo;s sole
              discretion and the final disbursement amount shall be based on the
              Lender&rsquo;s internal policies and eligibility criteria
              applicable to the Borrower. 1.4. The Borrower understands that
              this Agreement is valid for the Loan Period. 1.5. At the request
              of the Borrower, the Lender may in its sole discretion agree to
              the Borrower re-borrowing any amount(s) under this Agreement for
              such amounts and for such further periods as the Lender may deem
              fit in its sole discretion and/or in compliance with applicable
              laws and including, the Lender&rsquo;s discretion in revising any
              of the conditions for the Loan including, specifying additional
              conditions, which shall all be binding on the Borrower. The rate
              of interest/tenure and change in conditions shall be as intimated
              by the Lender to the Borrower. Notwithstanding this, upon any such
              request being made by the
            </p>

            <p>
              {" "}
              Borrower, the Borrower shall have deemed to have warranted and
              represented to the Lender that no Event of Default has occurred
              and/or is in existence or continuing. 1.6. The Loan shall be
              subject to the terms and conditions agreed herein between the
              Lender and the Borrower. 2. DISBURSAL OF THE LOAN 2.1 The Loan
              will be disbursed to the Borrower in one or more instalment(s) as
              may be required by the Borrower. The disbursement shall be through
              the agreed mode of disbursement in the loan application journey
              completed by the Borrower in relation to availing loan(s) through
              mobile application. 2.3 In case of purchase made by Customer at
              Co-Lenders tied up merchants, the Customer&rsquo;s request for
              loan shall be processed as per the standard practice of Co-lenders
              and pursuant to Valid Confirmation by the Customer, it shall be
              deemed as disbursed once the Loan amount or part of the Loan
              amount is transferred from Co-Lenders account to the Merchant
              Account. 3. INTEREST AND CHARGES 3.1. The Borrower shall pay
              interest, default interest along with processing charges, late
              payment charges and ECS/NACH/ cheque bounce charges (collectively
              &ldquo;Charges&rdquo;) at the rate as detailed in Annexure 1
              herein, and as per the time frame agreed by the Borrower in the
              Website. The interest shall be calculated on the daily balance of
              the outstanding Loan. 3.2. Notwithstanding the above, the Borrower
              understands that the rate of interest or the Charges, may change
              as per the policies of the Lender or as per applicable laws and
              regulations. The revised interest or charges shall be communicated
              to the Borrower through email or otherwise. On account of upward
              revision of interest or Charges, the Lender will have absolute
              discretion to revise the instalments applicable to the repayment
              of the Loan, and the Borrower shall be liable to repay the Loan
              based on such revised interest or the Charges. The Lender shall
              ensure that such change in the rate of interest or the Charges is
              effected only prospectively that is for Loans that are availed
              after such change. Rate of interest and related Charges shall be
              communicated by the Lender to the Borrower each time upon every
              future Loan. 3.3. The Borrower understands, agrees and confirms
              that in case the Borrower borrows any amount under the Loan
              agreement, a processing charge shall be paid by the Borrower each
              time a Loan is borrowed at the rate as communicated to the
              Borrower prior to disbursement of such Loan. 3.4. In the event the
              Borrower commits a default in the payment of Amount Due (defined
              below) against the Loan or the Borrower commits any breach of any
              obligations under this Agreement or under any other instrument in
              respect of the Loan, the Borrower shall be liable to pay default
              interest and applicable Charges at the rate as specified in
              Annexure 1 herein, with monthly rests, from the date of default
              till the date of realization by the Lender. The payment of default
              interest and Charges shall not absolve the Borrower of the other
              obligations in respect of such breach or default or affect the
              rights of the Lender in respect of the breach or default.
            </p>

            <p>
              {" "}
              4. REPAYMENT AND PREPAYMENT OF THE LOAN 4.1. The Borrower agrees
              to repay the Loan installment(s) together with interest, default
              interest (if any) or the Charges, dues and taxes (as applicable)
              (Amount Due) on the due date/s agreed by the borrower in Website/.
              Borrower understands that its obligations under this Agreement,
              shall be valid and binding, until the repayment of the Amount Due,
              to the satisfaction of Lender. 4.2. At the request of the
              Borrower, Lender may accept the prepayment and foreclosure of the
              Loan as per the terms agreed under the Agreement. 4.3. The
              Borrower will remain liable under/in respect of the Repayment
              Instrument(s) (defined below), even if partial payments have been
              made towards the Loan, from time to time, or the Loan amount has
              been revised. In case of death or insolvency of the Borrower, the
              outstanding Loan along with Interest, default interest and the
              Charges will be recovered from his/her legal heir/s, legal
              representative/s, 5. REPAYMENT CONSENT FOR THIRD PARTY 5.1. The
              Borrower has given Electronic Clearing System
              (&ldquo;ECS&rdquo;)/NACH mandate, cheque (collectively
              &ldquo;Repayment Instrument&rdquo;) in favor of the Lender for the
              repayment of the Loan now or hereafter. The Borrower hereby
              unconditionally and irrevocably authorizes the Lender or any other
              enforcing agency to enforce the Repayment Instruments towards
              payment of the Amount Due. The Borrower shall ensure availability
              of funds in the account on which such Repayment Instrument(s) have
              been drawn and the Borrower shall not at any time close his/her
              account and/or issue any notice instructing the Lender not to
              present any such Repayment Instrument(s) for encashment or
              instruct his/her bank to stop payment on any such Repayment
              Instrument(s). 5.2. The Borrower further confirms that the Loan
              under this Agreement has been made available on the basis of the
              Repayment Instrument(s) issued by the Borrower in favor of the
              Lender. The Repayment Instruments remain valid for all Loan along
              with interest, default interest and Charges. 5.3. In case the
              Borrower does not comply with the aforesaid, the Lender shall
              still be entitled to present/invoke the Repayment Instrument(s).
              The dishonor/failure of the Repayment Instrument(s) shall be
              treated as an Event of Default under this Agreement. 6. EVENT OF
              DEFAULT The Borrower shall be deemed to have committed an act of
              Default if the Borrower does not comply with his/her obligations
              as mentioned in this Agreement and also on the happening of one or
              more of the following events (each an &ldquo;Event of
              Default&rdquo; and collectively &ldquo;Events of Defaults&rdquo;):
              (a) Default has occurred in the repayment of the Loan along with
              interest, default interest or the Charges on the due date/s or on
              demand whether under this Agreement or under any other Agreement
              with the Company;
            </p>

            <p>
              {" "}
              (b) In the opinion of the Lender, Borrower has breached the
              representations and warranties and the covenants provided under
              this Agreement. (c) In the opinion of the Lender, the Borrower
              have defaulted in the performance of any Covenant, condition or
              agreement under this Agreement. (d) The Borrower are or becomes a
              party to any litigation, arbitration, administrative or other
              action, investigation by any governmental entity, claim, suit or
              proceedings which would have a material adverse effect on the
              terms of this Agreement. (e) The Borrower fails to furnish any
              information or document that may be required by the Lender from
              time to time; or (f) If any proceedings are pending or threatened
              against the Borrower by any Authority for any misconduct or
              breach/violation of any law or regulations or code of conduct,
              etc. Upon occurrence of an Event of Default, a. the outstanding
              amount of the Loan shall forthwith become payable to the Lender,
              without any notice to the borrower . b. Terminate the Loan and
              declare all obligations immediately due and payable; and/or c.
              Stop advancing money or extending credit for the Borrower&rsquo;s
              benefit under this Agreement or any other agreement between the
              Borrower and the Company, and terminate the right of the Borrower
              to avail of or make drawals from the loan; and/or d. Take any
              action and proceed under any applicable law or statute against the
              Borrower. e. Enforce the Security, if any available; 7. REMEDIES
              IN CASE OF DEFAULT The Lender shall have the following remedies
              without prejudice to its other rights and remedies under this
              Agreement, in law or inequity: 7.1. The Lender shall be entitled
              to recover the charges for bouncing of the Repayment
              Instrument(s), default interest and other penal charges from the
              Borrower as mentioned in Annexure 1. 7.2. Any other remedy which
              may be available under law during the pendency of the Loan
              including without limitation the remedy under section 138 of the
              Negotiable Instruments Act, 1881 and under section 25 (1) of
              Payment of Settlement System Act, 2007. 7.3. In addition to the
              rights specified in this Agreement, the Lender shall be entitled
              to take all or any action with or without intervention of the
              Courts to recover the monies due and payable by the Borrower under
              this Agreement. 7.4. Notwithstanding any other rights available to
              the Lender under this Agreement, the Lender shall be entitled to
              initiate criminal proceeding or any other appropriate actions
              against the Borrower if at any time the Lender at its sole
              discretion has sufficient grounds to believe that the Borrower has
              made any misrepresentations and / or submitted any forged
              documents or fabricated data to the Lender.
            </p>

            <p>
              {" "}
              7.5. All rights and powers conferred on the Lender by this
              Agreement shall be in addition and supplemental to any rights the
              Lender has as a creditor against the Borrower under any law for
              the time being in force and security documents and shall not be in
              derogation thereof. 8. REPRESENTATIONS AND WARRANTIES 8.1.
              Borrower represents and warrants to the Lender as follows: (a)
              upon execution by the Borrower, this Agreement will constitute a
              valid, enforceable and legally binding obligation of the Borrower;
              (b) the Terms and Conditions and the information provided are
              binding on the Borrower. (c) there is no law, regulation, decree
              or act and no provision of any existing contract, agreement or
              instrument to which Borrower is a party that would be contravened
              by this Agreement or by the performance or observance of any of
              its terms; 8.2. The representations and warranties above shall be
              deemed to be repeated by the Borrower on and as of each day from
              the date of this Agreement until all the outstanding Loan,
              interest, default interest and the Charges due or owing hereunder
              by the Borrower to the Lender have been repaid in full. 9.
              COVENANTS The Borrower hereby covenants that the Borrower shall:
              (a) ensure timely payment of the Amount Due and ensure that the
              Repayment Instrument(s) are honored on presentation. (b) utilize
              the Loan only for personal use and not use the same for any other
              purpose including making investment in the capital market or for
              any speculative purposes, unlawful purposes and/or anti-social
              purpose. The Lender shall have the right to recall the Loan if the
              funds are used for any speculative, unlawful and/or anti-social
              purposes; (c) promptly notify the Lender through a written notice,
              of any change in the Borrower&rsquo;s mobile no., postal address
              or any other details provided in the Application Form. (d) Execute
              any and all documents, as the Lender may require, from time to
              time, for duly or more perfectly securing the repayment of the
              Loan. (e) Utilize any money received under an insurance claim by
              the borrower, including legal heirs, for the repayment of the
              outstanding amount of the Loan. (f) not default or delay in the
              repayment of outstanding Loan amount along with interest, default
              interest or the Charges and dues beyond the respective due dates.
              10. AUTHORIZATION AND DISCLOSURES 10.1. The Borrower authorizes
              the Lender to engage or appoint one or more person(s) to verify
              any fact or information furnished by, concerning and pertaining to
              the Borrower and/or to collect any and all Borrower information or
              activities relating to the administration of the Loan including
              the rights and authority to collect and receive on behalf of the
              Lender all dues and unpaid installments and other amounts due by
              Borrower under this Agreement. The Borrower expressly accepts and
              authorizes the Lender (and/or any such third party as the Lender
              may appoint from time to time) to contact third parties (including
              the family members of the Borrower ) and disclose all necessary or
              relevant information pertaining to the Borrower and the Loan, and
              the Borrower hereby consents
            </p>

            <p>
              {" "}
              to such disclosure by the Lender (and/or any such third party as
              the Lender may appoint) 10.2. The Borrower also authorizes the
              Lender or any third party (authorized by the Lender) to collect
              data from Credit Information Companies (&ldquo;CICs&rdquo;). 10.3.
              The Borrower agrees and authorizes the Lender to disclose, from
              time to time, any information relating to the Borrower and/or the
              Loan to; (a) any employee or representative of the Lender or to
              third parties engaged by the Lender , from time to time, for
              purposes of this Agreement; (b) the CICs and other agencies
              authorized by the Reserve Bank of India or any other regulatory
              authority, which may use, process, disclose and furnish the said
              information, in any manner as it may deem fit; (c) to any group
              companies and/or to any of the Lender&rsquo;s service providers,
              insurers or insurance brokers or professional advisers; and/or
              credit rating agencies. 10.4. The Lender shall give notice to the
              Borrower of any change in the terms and conditions including the
              disbursement schedule, interest rates, the Charges, repayment
              terms etc. The Borrower shall keep himself/herself abreast with
              all such notifications and confirms that he/she would be bound by
              such change, without any dispute. 11. DISPUTE RESOLUTION AND
              GOVERNING LAW, Any dispute or difference arising out of or in
              connection with this Agreement shall be referred to arbitration,
              in accordance with the Arbitration and Conciliation Act, 1996. The
              seat of arbitration shall be at Delhi and the arbitration shall be
              conducted in the English language. The arbitral tribunal shall
              constitute of a sole arbitrator namely Sh.Gaurav Sharma, E 2/2,
              Adarsh Nagar, Delhi 110033. The award of the arbitrator shall be
              final and binding upon the Parties. This Agreement shall be
              construed in accordance with the laws of India and the courts of
              law at Delhi, India shall have exclusive jurisdiction on the
              matters arising out of or in connection with this Agreement. 12.
              MISCELLANEOUS 12.1. The Borrower hereby state that the contents of
              the Loan Documents have been read over and explained to the
              Borrower in the local language and that the Borrowerhave
              understood the contents of the Loan Documents and only then
              affixed the signature to the said Loan Documents. 12.2. Except as
              otherwise provided herein, all Charges and expenses incurred in
              connection with or related to this Agreement shall be paid by the
              Borrower. The Borrower shall be liable to pay any tax, stamp duty,
              stamp duty penalties, registration Charges, penalties as may be
              incurred by the Borrower or the Lender, for performance or
              enforcement of this Agreement. 12.3. The parties hereto confirm
              and acknowledge that this Agreement, Application Form, and the
              Annexure(s) herein, constitute the entire agreement between them
              and shall supersede and override all previous communica-tions,
              either oral or written, between the parties with respect to the
              subject matter of this Agreement, and no agreement or under-
              standing varying or extending the same shall be binding upon any
              Party hereto unless arising out of the specific provisions of this
              Agreement.
            </p>

            <p>
              {" "}
              12.4. Should any part of this Agreement be declared illegal or
              unenforceable by a court of competent jurisdiction, the same shall
              not alter the validity or enforceability of any other term or
              provision of this Agreement. 12.5. The Borrower shall not in any
              manner be entitled to assign his/her rights and obligations under
              this Agreement. Notwithstanding anything to the contrary contained
              in the Agreement, the Lender shall have the right (in full or in
              part) to assign and/or transfer and/or novate its rights or
              obligations under this Agreement and/or the outstanding amount or
              other arrangement for risk sharing, whether with or without
              recourse to the Lender, to one or more banks or any other entity,
              trust, any association, without any reference or notice to the
              Borrower . 12.6. No amendment, modification or addition to this
              Agreement shall be effec-tive or binding upon either of the
              parties hereto unless set forth in writing and executed by them
              through their duly authorized representatives. 12.7 The borrower
              is working with an employer as stated in loan application form and
              the borrower may have an option to repay the EMI amount through
              salary deduction wherein, the borrower hereby authorises its
              employer to debit outstanding dues from the salary account in
              favour of lender. The borrower shall issue a separate letter as
              well stating entire details in due course.
            </p>

            <p>
              {" "}
              ANNEXURE 1 Borrower Name: {name} Address: {address} Effective
              Date: {day}/{month}/{year} Limit: {amountRequested} Amount to paid
              Back: {amountToBePaidBack} Repayement Date: {dueDate} Name as per
              POI: {name} As of Effective Date, the Schedule of Interest and the
              Charges are as follows: 1. Interest on the Loan Interest rate:
              {annualInterestRate}% p.a. charged on monthly rests. 2. Charges A.
              Processing Fees: Rs 0 B. Late payment charges: In case of delay in
              payment of Amount Due, the Lender shall charge late payment
              charges of Rs. 500 per month. Overdue interest on overdue amount
              not exceeding 30% per annum. C. ECS/NACH mandate reject charge: In
              the event, the ECS/NACH mandate registration is rejected, the
              Lender will charge rejection charges not exceeding Rs 500/- per
              rejection. D. Charges for bouncing of the Repayment Instrument(s):
              In case of default by reason of the Repayment Instrument(s) being
              dishonoured, Lender shall charge 500/- per month / per default
              towards its dishonour. E. Prepayment Charges: Not exceeding 2% of
              the Prepaid Amount. f. Stamp Duty:
            </p>

            <p>
              {" "}
              Stamp duty &ndash; 0.1% of the sanction amount or Rs. 100,
              whichever is higher rounded upto nearest 100. The amount on NACH
              Mandate is 5% higher than the existing sanctioned loan amount as
              it covers possible increase in limit in near future, processing
              fee and Interest levy and other charges levy in event of default.
              The Lender, may at its sole discretion, waive or reduce the
              charges as mentioned above, on a case to case basis. IN WITNESS
              WHEREOF the Parties have executed this Agreement on the day and
              the year as mentioned in the First Schedule. OTP verified by{" "}
              {name} on {day}/{month}/{year} via Mobile No {phoneNumber} SIGNED
              AND SEALED BY M/s Taru Agencies and Investments Pvt Ltd through
              its authorized signatory Authorised Signatory
            </p>

            <p>
              {" "}
              Repayment Schedule : Due Date Principal Amount Interest Amount Fee
              Charges Amount EMI Amount {dueDate} {amountRequested}{" "}
              {amountToBePaidBack - amountRequested} 0 {amountToBePaidBack}
            </p>

            <p>
              {" "}
              CKYC T&amp;amp;C accepted at the end of loan acceptance &ndash;
              confirmation Page
            </p>

            <p>
              {" "}
              I out of my own free will and without any undue influence,
              coercion, fraud, mistake, misrepresentation provide my consent to
              TARU AGENCIES AND INVESTMENTS PRIVATE LIMITED i.e. Lenders to
              collect, store, and use the data collected through various modes
              but not limited to Central KYC Registry, UIDAI and other Know Your
              Customer Authentication methods for compliance with Know Your
              Customer requirements as per KYC Master Direction 2016 in
              accordance with the Prevention of Anti-Money Laundering Act 2002.
              I understand that such collected data is used and retained to
              ascertain compliance with the above specified regulations or any
              other applicable law/regulations and I hereby expressly consent to
              use, access, modify, store, download, upload or take any other
              action in relation to any data stored or accessed through the
              Central KYC Registry for the above purposes.
            </p>

            <p> I accept the terms and conditions</p>

            <p>
              {" "}
              KFS (Key Fact Statement) &ndash; Need to confirm at last
              confirmation Page
            </p>

            <p>
              {" "}
              KEY FACT STATEMENT Date: {day}/{month}/{year} Lender&rsquo;s Name:
              TARU AGENCIES AND INVESTMENTS PRIVATE LIMITED Applicant Name:{" "}
              {name}
            </p>

            <p>
              {" "}
              Sr.No. Parameter Details (i) Loan amount (in Rupees){" "}
              {amountRequested} (ii) Interest amount (in Rupees){" "}
              {amountToBePaidBack - amountRequested} (iii) Other up-front
              charges, if any (break-up of each component to be given below) (in
              Rupees) (a) Processing fees, if any (in Rupees) 0.00 (b) Insurance
              charges, if any (in Rupees) 0.00 (c) Others (if any) (in Rupees)
              (GST+ Stamp Duty) 0.00 (iv) Net disbursed amount
              ((i)-(ii(b))-(iii)) (in Rupees) {amountRequested} (v) Total amount
              to be paid by the borrower (sum of (i) and (ii(a))) (in Rupees){" "}
              {amountToBePaidBack} (vi) Annual Percentage Rate - Effective
              annualized interest rate (in percentage) (computed on net
              disbursed amount using IRR approach and reducing balance method)
              {annualInterestRate}% (vii) Tenor of the Loan (in months){" "}
              {loanDurationInDays} (viii) Repayment frequency by the borrower
              MONTHLY (ix) Number of installments of repayment 1 (x) Amount of
              each installment of repayment (in Rupees) {amountToBePaidBack}{" "}
              Details about Contingent Charges
            </p>

            <p>
              {" "}
              Sr.No. Parameter Details (xi) Rate of annualized penal charges in
              case of delayed payments (if any) 30.00% (xii) Rate of annualized
              other penal charges (if any); (details to be provided ) 36.00%
              (xiii) Prepayment charges, if any NA (xiv) Penal Interest, in case
              of delayed payments 30.00% PA (xv) Late Payment charges in case of
              delayed payment 500+GST or 3% of overdue whichever is higher Other
              disclosures Sr.No. Parameter Details (xviii) Cooling off/look-up
              period (in days) during which borrower shall not be charged any
              penalty on prepayment of loan 5 (xix) Name, designation, address
              and phone number of nodal grievance redressal officer designated
              of RE specifically to deal with digital lending related
              complaints/ issues Ms Komal Mishra, SH-11/175, New Moti Nagar, New
              Delhi -110015, M No. Mr.
            </p>

            <p>
              {" "}
              Detailed Repayment Schedule INSTALLMENT NO. PRINCIPAL In Rupees
              INTEREST In Rupees INSTALLMENT In Rupees 1 {amountRequested}{" "}
              {amountToBePaidBack - amountRequested} {amountToBePaidBack}
            </p>

            <p>
              {" "}
              The Borrower can within the cooling-off/ look-up period exit the
              loan by paying the principal and the proportionate APR without any
              penalty. The Borrower shall note that the account shall be
              classified as Non-Performing Asset (NPA) as per the extant RBI
              Circulars/Regulations when the payment of Loan Instalment
              (principal and/or interest) remains overdue for more than 90 days,
              that is, the account will be marked as NPA on the 91st day of
              continuous default from the original due date. To further clarify
              this following is an illustrative example of the way any account
              shall be classified as NPA: If due date of a loan account is March
              31st, 2021, and full dues (Principal and Interest) are not
              received before running of the day-end process on 31st March 2021,
              the date of overdue shall be considered to be March 31st, 2021. If
              the account continues to remain overdue, then the account shall
              get tagged as SMA-1 upon running day-end process on April 30th,
              2021, i.e., upon completion of 30 days of being continuously
              overdue. Accordingly, the date of SMA-1 classification for that
              account shall be April 30th, 2021. Similarly, if the account
              continues to remain overdue, it shall get tagged as SMA-2 upon
              running day-end process on May 30th, 2021, and if it continues to
              remain overdue further, it shall get classified as NPA upon
              running day-end process on June 29th, 2021, i.e., upon completion
              of 90days. Upon the account of the borrower being 1 day past due
              the details of the borrower shall be shared with our official
              collection executives the detail of whom shall be shared with the
              individual borrower, and the collection officers/executives shall
              reach out to the borrower on behalf of the Lender on his
              registered contact number and/or address. In case of any issues
              regarding any Recovery Practices implemented, a complaint can be
              raised according to the Grievance Redressal Policy available on
              the website of the RE (Regulated Entity). For any Grievance you
              can contact the Nodal Officer of the Lender in the manner
              specified in the Grievance Redressal Policy of the RE(Regulated
              Entity).
            </p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={onOkClicked} noRipple={true}>
              Okay
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
