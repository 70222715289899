import { createUrl, post } from "../../../network/AxiosInterceptor";
import { Buffer } from "buffer";

const IMAGE_TYPES = {
  PROFILE_PIC: "profile_pic",
  PAN_CARD: "pan_card",
  AADHAR_FRONT: "aadhar_front",
  AADHAR_BACK: "aadhar_back",
};

export const uploadImage = async (
  userPic,
  panPic,
  aadharPicFront,
  aadharPicBack,
  onSuccess
) => {
  const formData = new FormData();
  formData.append("photos[]", dataURItoBlob(userPic), IMAGE_TYPES.PROFILE_PIC);
  formData.append("photos[]", dataURItoBlob(panPic), IMAGE_TYPES.PAN_CARD);
  formData.append(
    "photos[]",
    dataURItoBlob(aadharPicFront),
    IMAGE_TYPES.AADHAR_FRONT
  );
  formData.append(
    "photos[]",
    dataURItoBlob(aadharPicBack),
    IMAGE_TYPES.AADHAR_BACK
  );

  post(createUrl("/api/image/upload-user-docs"), formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      console.log(response);
      onSuccess();
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Helper function to convert dataURI to Blob
const dataURItoBlob = (dataURI) => {
  const buffer = Buffer.from(dataURI.split(",")[1], "base64");
  let blob = new Blob([buffer], { type: "image/jpeg" });
  return blob;
};

// Helper function to convert dataURI to Blob
// const dataURItoBlob = (dataURI) => {
//   var binary = atob(dataURI.split(",")[1]);
//   var array = [];
//   for (var i = 0; i < binary.length; i++) {
//     array.push(binary.charCodeAt(i));
//   }
//   let blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
//   return blob;
// };
