import moment from "moment";
import { OrderResponseDto } from "./OrderDto";

export class OrderViewModel {
    createdDate: string = ""
    amount: number = 0
    durationInDays: number = 0
    status: string = ""
}

export const mapToOrderViewModel = (orderResponseDto: OrderResponseDto) => {
    let orderViewModel = new OrderViewModel()
    let createdAtAsMoment = moment(orderResponseDto.createdAt);
    orderViewModel.createdDate = createdAtAsMoment.format('ll');
    orderViewModel.amount = orderResponseDto.amountRequested
    orderViewModel.durationInDays = orderResponseDto.loanDurationInDays
    orderViewModel.status = orderResponseDto.status
    return orderViewModel
};