import { createUrl, patch } from "../../../network/AxiosInterceptor";

export const updateKycStatusUseCase = async (employeeId, kycStatus) => {
  try {
    const response = await patch(createUrl(`/api/employees/${employeeId}`), {
      kycStatus: kycStatus,
    });
    return response.data.success;
  } catch (error) {
    console.error("Error updating kyc status:", error);
    throw error;
  }
};
