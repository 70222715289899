import { Data } from "dataclass";
import {
  GetQuoteResponseDto,
  Installment,
  LoanDurationWiseDetails,
} from "./get-quote.dto";

export class QuoteViewModel extends Data {
  loanDurationInDays: string = "";
  amountRequested: string = "";
  amountToBePaidBack: string = "";
  dueDate: string = "";
  quoteRefId: string = "";
  installmentRows: InstallmentTableRow[] = [];
  annualInterestRate: number = 0;
  termsAndConditions: string =
    "By clicking submit, you agree to all our terms and conditions.";
}

export class InstallmentTableRow extends Data {
  label: string = "";
  value: string = "";
}

export const mapToRequestQuoteViewModel = (
  amountRequested: number,
  loanDurationWiseDetails: LoanDurationWiseDetails
): QuoteViewModel => {
  return QuoteViewModel.create({
    amountRequested: `₹ ${amountRequested}`,
    loanDurationInDays: `${loanDurationWiseDetails.loanDurationInDays} days`,
    amountToBePaidBack: `₹ ${loanDurationWiseDetails.amountToBePaidBack}`,
    dueDate: loanDurationWiseDetails.lastPaymentDate,
    quoteRefId: loanDurationWiseDetails.quoteRefId,
    annualInterestRate: loanDurationWiseDetails.annualInterestRateInPercent,
    installmentRows: createInstallmentTableRows(
      loanDurationWiseDetails.installmments
    ),
  });
};

function createInstallmentTableRows(
  installments: Installment[]
): InstallmentTableRow[] {
  return installments.flatMap((installment) => [
    InstallmentTableRow.create({
      label: installment.intstallmentNumberText,
      value: installment.amountLabel,
    }),
    InstallmentTableRow.create({
      label: installment.dateOfPaymentLabel,
      value: installment.dateOfPayment,
    }),
  ]);
}
