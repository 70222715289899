import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

export const AdminOrderDetailsView = ({ orderData }) => {
  if (!orderData) {
    return <div>Loading...</div>;
  }

  const { order, employee } = orderData;

  return (
    <MDBContainer>
      <MDBRow className="mt-4">
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <h3>Order Information</h3>
              <hr />
              <p>
                <strong>Order ID:</strong> {order.id}
              </p>
              <p>
                <strong>Created At:</strong> {order.createdAtLabel}
              </p>
              <p>
                <strong>Updated At:</strong> {order.updatedAtLabel}
              </p>
              <p>
                <strong>Reference Quote ID:</strong> {order.quoteRefId}
              </p>
              <p>
                <strong>Order Status:</strong> {order.status}
              </p>
              <p>
                <strong>Amount Requested:</strong> {order.amountRequested}
              </p>
              <p>
                <strong>Amount To be Paid Back:</strong>{" "}
                {order.amountToBePaidBack}
              </p>
              {order.installments.length > 0 &&
                order.installments.map((installment, index) => (
                  <div>
                    <p>
                      <strong>{`Installment ${index + 1}:`}</strong>
                    </p>
                    <p>
                      <strong>Amount:</strong> {installment.amountLabel}
                    </p>
                    <p>
                      <strong>Due Date:</strong> {installment.dateLabel}
                    </p>
                    <p>
                      <strong>Status:</strong> {installment.status}
                    </p>
                  </div>
                ))}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody>
              <h3>Employee Information</h3>
              <hr />
              <p>
                <strong>Employee ID:</strong> {employee.employeeId}
              </p>
              <p>
                <strong>Employee Name:</strong> {employee.name}
              </p>
              <p>
                <strong>Employee Phone Number:</strong> {employee.phoneNumber}
              </p>
              <p>
                <strong>Company Name:</strong> {employee.companyName}
              </p>
              {/* ...other employee details */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
