import "./App.css";
import { HomeContainer } from "./components/home/HomeContainer.js";
import { Routes, Route } from "react-router-dom";
import { MainContainer } from "./components/main/MainContainer";
import AdminLoginContainer from "./components/adminlogin/AdminLoginContainer";
import AdminOrdersContainer from "./components/adminorders/AdminOrdersContainer";
import { KycEmployeeList } from "./components/adminkyc/KycEmployeeList";
import { KycApprovalContainer } from "./components/adminkyc/KycApprovalContainer";
import { TermsAndConditions } from "./components/tnc/TermsAndConditions";
import { AdminOrderDetailsContainer } from "./components/adminorderdetails/AdminOrderDetailsContainer";
import { AdminEmployeeDetailsContainer } from "./components/adminemployeedetails/AdminEmloyeeDetailsContainer";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/me" element={<MainContainer />} />
        <Route path="/login" element={<HomeContainer />} />
        <Route path="/admin/login" element={<AdminLoginContainer />} />
        <Route path="/admin" element={<AdminOrdersContainer />} />
        <Route
          path="/admin/orders/:orderId"
          element={<AdminOrderDetailsContainer />}
        />
        <Route
          path="/admin/employee/:employeeId"
          element={<AdminEmployeeDetailsContainer />}
        />
        <Route path="/admin/kyc" element={<KycEmployeeList />} />
        <Route path="/admin/kyc/:userId" element={<KycApprovalContainer />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

export default App;
