import { useState, useRef, useEffect } from "react";
import { CSVLink } from "react-csv";
import { fetchOrdersUseCase } from "./usecase/FetchOrdersUseCase";
import moment from "moment";

export const DownloadOrdersLinkView = ({
  currentOrderFilter,
  selectedCompany,
  selectedDate,
}) => {
  const [loading, setLoading] = useState(false);
  const orderRef = useRef([]);
  const [canDownload, setCanDownlaod] = useState(false);

  useEffect(() => {
    setCanDownlaod(false);
  }, [currentOrderFilter, selectedCompany, selectedDate]);

  const fetchOrders = (event, done) => {
    if (!loading) {
      setLoading(true);
      fetchOrdersUseCase(
        0,
        currentOrderFilter,
        selectedCompany,
        selectedDate,
        1000
      )
        .then((response) => {
          let orders = response.data.data.docs.map(mapOrderDto);
          orderRef.current = orders;
          setLoading(false);
          setCanDownlaod(true);
        })
        .catch((error) => {
          setLoading(false);
          setCanDownlaod(false);
          console.log(error);
        });
    }
  };

  function mapOrderDto(order) {
    return {
      orderId: order.id,
      employeeName: order.employee.fullName,
      phoneNumber: order.employee.phoneNumber,
      createdAt: moment(order.createdAt).format("ll"),
      updatedAt: moment(order.updatedAt).format("ll"),
      quoteRefId: order.quoteRefId,
      companyName: order.company.name,
      amountRequested: order.amountRequested,
      amountToBePaidBack: order.amountToBePaidBack,
      status: order.status,
    };
  }

  return (
    <CSVLink
      filename={"report.csv"}
      data={orderRef.current}
      onClick={(event) => {
        if (canDownload) {
          return true;
        }
        fetchOrders();
        return false; // 👍🏻 You are stopping the handling of component
      }}
    >
      {loading ? "Loading csv..." : canDownload ? "Download CSV" : "Create CSV"}
    </CSVLink>
  );
};
