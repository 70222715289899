import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import "./HomeView.css";
import Select from "react-select";
import { CustomDialog } from "../shared/Dialog";

export const LoginMode = {
  NONE: 0,
  PHONE_NUMBER: 1,
  EMPLOYEE_ID: 2,
};

export const HomeView = ({
  companyNames,
  isLoading,
  loginMode,
  onCompanySelected,
  onGetOtpClicked,
  showSignInLoader,
  isSignInDisabled,
  onEmployeeIdChange,
  onPhoneNumberChange,
  dialogModel,
  showOtpInputField,
  onOtpChanged,
  otpButtonState,
}) => {
  // const [basicModal, setBasicModal] = useState(true);

  // const toggleShow = () => setBasicModal(!basicModal);

  return (
    <MDBContainer className="my-5 mt-0 mb-0">
      <MDBRow className="p-0">
        <MDBCol
          col="12"
          className="no-gutters align-items-center justify-content-center gradient-custom-2"
        >
          <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">
                Get an advance on your next Pay Check in under 24 hours!
              </h4>
              <p class="small mb-0">
                Taru Pay is a short-term loan offering service provided by Taru
                Agencies & Investments Pvt Ltd.
                <br></br>
                <br></br>Our mission is to get you through the unexpected
                expenses and emergencies, without having to ask for money from
                unreliable sources.{" "}
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol className="col-12 col-md-6 mx-auto">
          <div className="d-flex flex-column ms-1 me-1 mt-2">
            <div className="text-center">
              <img
                src="https://i.ibb.co/bRd1Bt3/Whats-App-Image-2023-04-23-at-6-05-18-PM.jpg"
                style={{ width: "185px" }}
                alt="logo"
              />
              <h4 className="mt-1 mb-4 pb-1">Welcome to TaruPay.in</h4>
            </div>

            <p>Please login to your account</p>

            <div class="mb-4">
              <Select
                placeholder="Select Company name"
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={!isLoading}
                isSearchable={!isLoading}
                options={companyNames}
                onChange={onCompanySelected}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? "No text" : "No results found"
                }
              />
            </div>

            {loginMode === LoginMode.EMPLOYEE_ID && (
              <MDBInput
                wrapperClass="mb-4"
                label="Employee Id"
                id="form1"
                type="text"
                onChange={(e) => {
                  onEmployeeIdChange(e);
                }}
              />
            )}

            {loginMode === LoginMode.PHONE_NUMBER && (
              <MDBInput
                wrapperClass="mb-4"
                label="Phone Number"
                id="typePhone"
                type="tel"
                onChange={(e) => {
                  onPhoneNumberChange(e);
                }}
              />
            )}

            {showOtpInputField && (
              <MDBInput
                wrapperClass="mb-4"
                label="Enter Otp (Otp can take upto a minute please do not refresh the page)"
                id="typeNumber"
                type="number"
                onChange={(e) => {
                  onOtpChanged(e);
                }}
              />
            )}

            <div className="text-center pt-1 mb-5 pb-1">
              {!otpButtonState.visible && (
                <MDBBtn
                  disabled={isSignInDisabled}
                  className="mb-4 w-100 gradient-custom-2"
                  noRipple={true}
                  onClick={onGetOtpClicked}
                >
                  {showSignInLoader && (
                    <MDBSpinner
                      size="sm"
                      role="status"
                      tag="span"
                      className="me-2"
                    />
                  )}
                  Get OTP
                </MDBBtn>
              )}

              {otpButtonState.visible && (
                <MDBBtn
                  disabled={!otpButtonState.enable}
                  className="mb-4 w-100 gradient-custom-2"
                  noRipple={true}
                  onClick={otpButtonState.onClick}
                >
                  {otpButtonState.loader && (
                    <MDBSpinner
                      size="sm"
                      role="status"
                      tag="span"
                      className="me-2"
                    />
                  )}
                  {otpButtonState.text}
                </MDBBtn>
              )}
              <div>
                By continuing you agree to our{" "}
                <a
                  href="https://tarupay.in/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
                .
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow style={{ backgroundColor: "grey" }}>
        <MDBCol col="6" className="p-0">
          <div className="d-flex flex-column  justify-content-center h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">Need Help?</h4>
              <p class="small mb-0">
                Contact us using the following details <br></br>
                Email:{" "}
                <a
                  href="mailto:payday@taruagency.com"
                  style={{ color: "white" }}
                >
                  payday@taruagency.com
                </a>{" "}
                <br></br>
                Mobile:{" "}
                <a href="tel:+919319584322" style={{ color: "white" }}>
                  93195 84322
                </a>{" "}
                <br></br>
                <br></br>
                For long-term loans, please reach out to us directly using the
                above mentioned contact details.
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>

      <CustomDialog dialogModel={dialogModel} />
    </MDBContainer>
  );
};
