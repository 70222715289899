import { createUrl, get } from "../../../network/AxiosInterceptor";
import moment from "moment";

export const fetchOrdersUseCase = async (
  currentPage,
  currentOrderFilter,
  selectedCompany,
  selectedDate,
  limit
) => {
  // Fetch orders from backend using pagination API
  let url = `/api/orders?page=${currentPage}&limit=${limit}`;
  if (currentOrderFilter !== "all") {
    url = `${url}&status=${currentOrderFilter}`;
  }
  if (selectedCompany && selectedCompany.id !== "All") {
    url = `${url}&company=${selectedCompany.id}`;
  }
  if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
    const formattedStartDate = moment(selectedDate.startDate).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(selectedDate.endDate).format("YYYY-MM-DD");
    url = `${url}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
  }
  return get(createUrl(url));
};
