import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBBtn,
} from "mdb-react-ui-kit";
import Select from "react-select";
import OrderRow from "./OrderRow";
import { ToastContainer } from "react-toastify";
import { DatePickerModalView } from "./DatePickerModalView";
import { CSVLink } from "react-csv";
import { DownloadOrdersLinkView } from "./DownloadOrdersLinkView";

function AdminOrdersView({
  companyNames,
  companyLoading,
  onCompanySelected,
  orders,
  currentPage,
  totalPages,
  onPageChange,
  onStatusChange,
  options,
  orderFilterOptions,
  currentOrderFilterValue,
  onFilterChange,
  onOrderIdClicked,
  onChooseDateClicked,
  showDatePicker,
  onDatePicked,
  onDatePickerClosed,
  dateLabel,
  selectedCompany,
  selectedDate,
}) {
  const tableHeaders = [
    "Order ID",
    "Employee Name",
    "Company Name",
    "Amount Requested",
    "Status",
  ];

  const handleFilterChange = (selectedOption, actionMeta) => {
    onFilterChange(selectedOption.value);
  };

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const handleStatusChange = (orderId, value) => {
    onStatusChange(orderId, value);
  };

  return (
    <div className="my-5 px-3">
      <h1 className="h1 fw-bold mb-4">Taru Pay</h1>
      <h2 className="h2 fw-bold mb-4">Orders</h2>
      <MDBRow className="justify-content-md-start justify-content-center align-items-center mb-4">
        <MDBCol md="auto" className="mb-2">
          <Select
            value={{
              value: currentOrderFilterValue,
              label: currentOrderFilterValue,
            }}
            options={orderFilterOptions}
            onChange={handleFilterChange}
          />
        </MDBCol>
        <MDBCol md="auto" className="mb-2">
          <Select
            placeholder="Select Company name"
            className="basic-single"
            classNamePrefix="select"
            isLoading={companyLoading}
            isClearable={!companyLoading}
            isSearchable={!companyLoading}
            options={companyNames}
            onChange={onCompanySelected}
            noOptionsMessage={({ inputValue }) =>
              !inputValue ? "No text" : "No results found"
            }
          />
        </MDBCol>
        <MDBCol md="auto" className="mb-2">
          <MDBBtn
            color="secondary"
            noRipple={true}
            onClick={onChooseDateClicked}
          >
            {dateLabel}
          </MDBBtn>
        </MDBCol>
        <MDBCol md="auto" className="mb-2">
          <DownloadOrdersLinkView
            currentOrderFilter={currentOrderFilterValue}
            selectedCompany={selectedCompany}
            selectedDate={selectedDate}
          />
        </MDBCol>
      </MDBRow>
      <MDBTable responsive striped bordered hover>
        <MDBTableHead color="primary-color" textWhite>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {orders.map((order) => (
            <OrderRow
              key={order.orderId}
              order={order}
              options={options}
              handleStatusChange={handleStatusChange}
              onOrderIdClicked={onOrderIdClicked}
            />
          ))}
        </MDBTableBody>
      </MDBTable>
      <MDBPagination className="my-4 justify-content-center">
        <MDBPaginationItem disabled={currentPage === 1}>
          <MDBPaginationLink onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </MDBPaginationLink>
        </MDBPaginationItem>
        {[...Array(totalPages)].map((_, page) => (
          <MDBPaginationItem key={page + 1} active={page + 1 === currentPage}>
            <MDBPaginationLink onClick={() => handlePageChange(page + 1)}>
              {page + 1}
            </MDBPaginationLink>
          </MDBPaginationItem>
        ))}
        <MDBPaginationItem disabled={currentPage === totalPages}>
          <MDBPaginationLink onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
      <ToastContainer />
      {showDatePicker && (
        <DatePickerModalView
          show={showDatePicker}
          onDatePicked={onDatePicked}
          onDatePickerClosed={onDatePickerClosed}
        />
      )}
    </div>
  );
}

export default AdminOrdersView;
