export class DialogModel {
    show: boolean
    title: string;
    body: string;
    positiveBtn: DialogButton;
    negativeBtn: DialogButton;
    onCrossClick: () => void;
    dialogType: number;
}

export class DialogButton {
    label: string;
    onClick: () => void;
}