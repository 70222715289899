import { createUrl, patch } from "../../../network/AxiosInterceptor";

const updateOrderStatus = async (orderId, status) => {
  try {
    const response = await patch(createUrl(`/api/orders/${orderId}`), {
      status: status,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating order status:", error);
    throw error;
  }
};

export { updateOrderStatus };
