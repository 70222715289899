import React, { useState } from "react";
import Webcam from "react-webcam";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from "mdb-react-ui-kit";

export const KycDialog = ({ show, onSubmitClicked, onCloseClicked }) => {
  const [step, setStep] = useState(0);
  const [profilePic, setProfilePic] = useState(null);
  const [panCardPic, setPanCardPic] = useState(null);
  const [aadharCardPic, setAadharCardPic] = useState(null);
  const [aadharCardPicBack, setAadharCardPicBack] = useState(null);

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setProfilePic(imageSrc);
    setStep(1);
  }, [webcamRef]);

  const handleRetake = () => {
    setProfilePic(null);
    setStep(step - 1);
  };

  const onCrossClicked = () => {
    setStep(0);
    setProfilePic(null);
    setPanCardPic(null);
    setAadharCardPic(null);
    setAadharCardPicBack(null);
    onCloseClicked();
  };

  const handleContinue = () => {
    setStep(step + 1);
  };

  const handlePanCardCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPanCardPic(imageSrc);
    setStep(step + 1);
  };

  const handleAadharCardCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setAadharCardPic(imageSrc);
    setStep(step + 1);
  };

  const handleAadharCardBackCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setAadharCardPicBack(imageSrc);
    setStep(step + 1);
  };

  const handleSubmit = () => {
    onSubmitClicked(profilePic, panCardPic, aadharCardPic, aadharCardPicBack);
  };

  const SelectImageButtons = () => {
    return (
      <div className="text-center mt-2">
        <MDBBtn color="secondary" onClick={handleRetake} noRipple={true}>
          Retake
        </MDBBtn>{" "}
        <MDBBtn color="primary" onClick={handleContinue} noRipple={true}>
          Continue
        </MDBBtn>
      </div>
    );
  };

  const webcamView = (
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      style={{
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );

  return (
    <MDBModal show={show} backdrop={true}>
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Complete Your Kyc</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onCrossClicked}
            />
          </MDBModalHeader>
          <MDBModalBody>
            {show && step === 0 && (
              <>
                {webcamView}
                <div className="text-center mt-2">
                  <MDBBtn color="primary" onClick={capture} noRipple={true}>
                    Capture Profile Pic
                  </MDBBtn>
                </div>
              </>
            )}
            {step === 1 && (
              <>
                <img src={profilePic} alt="Profile Pic" />
                <SelectImageButtons />
              </>
            )}
            {step === 2 && (
              <>
                {webcamView}
                <div className="text-center mt-2">
                  <MDBBtn
                    color="primary"
                    onClick={handlePanCardCapture}
                    noRipple={true}
                  >
                    Capture Pan Card Pic
                  </MDBBtn>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <img src={panCardPic} alt="Pan card Pic" />
                <SelectImageButtons />
              </>
            )}
            {step === 4 && (
              <>
                {webcamView}
                <div className="text-center mt-2">
                  <MDBBtn
                    color="primary"
                    onClick={handleAadharCardCapture}
                    noRipple={true}
                  >
                    Capture Aadhar Card Front
                  </MDBBtn>
                </div>
              </>
            )}
            {step === 5 && (
              <>
                <img src={aadharCardPic} alt="Aadhar Card Pic" />
                <SelectImageButtons />
              </>
            )}
            {step === 6 && (
              <>
                {webcamView}
                <div className="text-center mt-2">
                  <MDBBtn
                    color="primary"
                    onClick={handleAadharCardBackCapture}
                    noRipple={true}
                  >
                    Capture Aadhar Card Back
                  </MDBBtn>
                </div>
              </>
            )}
            {step === 7 && (
              <>
                <img src={aadharCardPicBack} alt="Aadhar Card Pic Back" />
                <SelectImageButtons />
              </>
            )}
            {step === 8 && "By pressing submit, we will start the Kyc process"}
          </MDBModalBody>
          <MDBModalFooter>
            {step === 8 && (
              <MDBBtn color="primary" onClick={handleSubmit} noRipple={true}>
                {" "}
                Submit
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
