import { createUrl, post } from "../../../network/AxiosInterceptor";
import { GetQuoteRequestDto, GetQuoteResponseDto } from "../get-quote.dto";

export class GetQuoteUseCase {
  public async execute(
    request: GetQuoteRequestDto
  ): Promise<GetQuoteResponseDto> {
    return post<GetQuoteResponseDto>(
      createUrl("/api/orders/getQuote"),
      request
    ).then((response) => {
      return response.data;
    });
  }
}
