import React, { useEffect, useRef, useState } from "react";
import { createUrl, post } from "../../network/AxiosInterceptor";
import { Employee } from "./Employee";
import { MainView } from "./MainView";
import { useNavigate } from "react-router-dom";
import { ButtonState } from "../shared/ButtonState";
import { OrderResponseDto } from "./OrderDto";
import { mapToOrderViewModel } from "./OrderViewModel";
import { DialogButton, DialogModel } from "../shared/DialogModel";
import { mapToRequestQuoteViewModel } from "./GetQuoteViewModel";
import { GetQuoteResponseDto } from "./get-quote.dto";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadImage } from "./usecase/UploadPhotoUseCase";
import { ImageUploadType } from "./constants/ImageUploadType";
import { KycStatus } from "../shared/model/KycStatus";
import { logoutUseCase } from "./usecase/LogOutUseCase";
import { setStoredJwt } from "../../network/AxiosInterceptor";
import { GetQuoteUseCase } from "./usecase/GetQuoteUseCase";

export const UiState = {
  REQUEST_QUOTE: 0,
  SUBMIT_REQUEST: 1,
};

export const MainContainer = () => {
  const navigate = useNavigate();

  let dialogModel = new DialogModel();
  dialogModel.show = false;
  const [dialog, setDialog] = useState(dialogModel);

  let emp = Employee.create();
  const [employee, setEmployee] = useState(emp);
  const uiStateRef = useRef(UiState.REQUEST_QUOTE);
  const [ordersState, setOrdersState] = useState([]);
  const [requestQuoteViewModel, setRequestQuoteViewModel] = useState();
  const employeeRef = useRef(emp);
  const arrOrderResponseDto = [];
  const orderRef = useRef(arrOrderResponseDto);
  const amountRef = useRef("");
  const timePeriodRef = useRef("");
  const [selectedLoanOption, setSelectedLoanOption] = useState(null);
  const [loanOptions, setLoanOptions] = useState(null);
  const lastQuoteResponse = useRef(null);
  const selectedLoanOptionRef = useRef(null);
  let submitButton = new ButtonState();
  submitButton.text = "Request Quote";
  submitButton.enable = false;
  const [submitButtonState, setSubmitButtonState] = useState(submitButton);
  const termsCheckboxRef = useRef(false);
  const [termsCheckboxState, setTermsCheckboxState] = useState(false);
  const [showKycDialog, setShowKycDialog] = useState(false);
  const now = new Date();
  const daysInMonth = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();
  const daysPassed = now.getDate();
  const daysRemaining = daysInMonth - daysPassed;

  const [isLoanAgreementOpen, setIsLoanAgreementOpen] = useState(false);
  const [isSanctionLetterOpen, setIsSanctionLetterOpen] = useState(false);

  const onClickShowSanctionLetter = () => {
    setIsSanctionLetterOpen(true);
  };

  const onClickShowLoanAgreement = () => {
    setIsLoanAgreementOpen(true);
  };

  const onSanctionLetterClosed = () => {
    setIsSanctionLetterOpen(false);
  };

  const onLoanAgreementClosed = () => {
    setIsLoanAgreementOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDetails = () => {
    const request = {
      employeeInfo: true,
      orderDetails: true,
    };
    post(createUrl("/api/details"), request)
      .then((response) => {
        let empJson = response.data.employee;
        let employee = Employee.create(empJson);
        let orderArrJson = response.data.orders;
        let ordersArr = orderArrJson.map((order) => {
          let orderDto = new OrderResponseDto();
          Object.keys(order).forEach((key) => (orderDto[key] = order[key]));
          return orderDto;
        });
        setEmployee(employee);
        employeeRef.current = employee;
        orderRef.current = ordersArr;
        setOrdersState(
          ordersArr.map((order) => {
            return mapToOrderViewModel(order);
          })
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            navigate("../login");
          }
        }
      });
  };

  const onAmountChanged = (event) => {
    const amount = event.target.value;
    amountRef.current = amount;
    updateSubmitButtonStateToRequestQuote();
    updateState(UiState.REQUEST_QUOTE);
  };

  const onDaysChanged = (event) => {
    const days = event.target.value;
    timePeriodRef.current = days;
    updateSubmitButtonStateToRequestQuote();
    updateState(UiState.REQUEST_QUOTE);
  };

  const onTermsCheckBoxChanged = (event) => {
    termsCheckboxRef.current = !termsCheckboxRef.current;
    setTermsCheckboxState(termsCheckboxRef.current);
  };

  function updateStateToRequestQuote() {
    updateSubmitButtonStateToRequestQuote();
    setRequestQuoteViewModel(null);
    setSelectedLoanOption(null);
    setLoanOptions(null);
    lastQuoteResponse.current = null;
    selectedLoanOptionRef.current = null;
    setTermsCheckboxState(false);
    termsCheckboxRef.current = false;
  }

  function updateSubmitButtonStateToRequestQuote() {
    let btnState = Object.assign({}, submitButtonState);
    btnState.text = "Request Quote";
    btnState.onClick = onSubmitClicked;
    btnState.loader = false;
    const amount = Number(amountRef.current);
    btnState.enable = amount > 0;
    setSubmitButtonState(btnState);
  }

  function updateState(newState) {
    if (newState === uiStateRef.current) {
      return;
    }

    if (newState === UiState.REQUEST_QUOTE) {
      updateStateToRequestQuote();
      uiStateRef.current = UiState.REQUEST_QUOTE;
      return;
    }

    if (newState === UiState.SUBMIT_REQUEST) {
      updateStateToSubmitRequest();
      uiStateRef.current = UiState.SUBMIT_REQUEST;
      return;
    }
  }

  function updateStateToSubmitRequest() {
    let btnState = Object.assign({}, submitButtonState);
    btnState.enable = true;
    btnState.loader = false;
    btnState.text = "Submit Request";
    btnState.onClick = onSubmitClicked;
    setSubmitButtonState(btnState);
    renderQuoteSection();
  }

  const onStartKycClicked = () => {
    setShowKycDialog(true);
  };

  const onSubmitKycClicked = (
    profilePic,
    panCardPic,
    aadharPicFront,
    aadharPicBack
  ) => {
    setShowKycDialog(false);
    uploadImage(
      profilePic,
      panCardPic,
      aadharPicFront,
      aadharPicBack,
      onKycDocsSubmitted
    );
  };

  const onKycDocsSubmitted = () => {
    let employee = employeeRef.current.copy({
      kycStatus: KycStatus.IN_PROCESS,
    });
    setEmployee(employee);
    employeeRef.current = employee;
  };

  const onCloseKycDialogClicked = () => {
    setShowKycDialog(false);
  };

  function onSubmitClicked() {
    if (!Number.isInteger(Number(daysRemaining))) {
      handleError("Input error", "Days can only take numbers");
      return;
    }

    if (!Number.isInteger(Number(amountRef.current))) {
      handleError(
        "Input error",
        "Amount can only take numbers, remove any decimal or alphabets"
      );
      return;
    }
    if (
      uiStateRef.current === UiState.SUBMIT_REQUEST &&
      !termsCheckboxRef.current
    ) {
      toast.error("Please accept the terms and conditions.", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        theme: "light",
      });
      return;
    }
    let btnState = Object.assign({}, submitButtonState);
    btnState.enable = false;
    btnState.loader = true;
    setSubmitButtonState(btnState);
    if (uiStateRef.current === UiState.REQUEST_QUOTE) {
      requestQuoteNew();
    } else {
      submitOrder();
      handleError(
        "Request Submitted",
        "You should receive the money into your bank account within 24 Hours!"
      ); //submit button successfully clicked
    }
  }

  function requestQuoteNew() {
    const request = {
      employeeId: employeeRef.current.employeeId,
      companyId: employeeRef.current.companyId,
      loanDurationInDays: Number(daysRemaining),
      amountRequested: Number(amountRef.current),
    };
    const usecase = new GetQuoteUseCase();
    usecase
      .execute(request)
      .then((quoteResponse) => {
        lastQuoteResponse.current = quoteResponse;
        updateState(UiState.SUBMIT_REQUEST);
      })
      .catch(function (error) {
        let btnState = Object.assign({}, submitButtonState);
        btnState.enable = true;
        btnState.loader = false;
        setSubmitButtonState(btnState);
        if (error.response.data) {
          handleError(
            error.response.data["errorTitle"],
            error.response.data["errorMessage"]
          );
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  function renderQuoteSection() {
    const loanDurationWiseDetailsArr =
      lastQuoteResponse.current.loanDurationWiseDetails;

    const loanOptions = loanDurationWiseDetailsArr.map((detail, index) => {
      return { id: index, label: detail.durationLabel };
    });

    setLoanOptions(loanOptions);
    setSelectedLoanOption(loanOptions[0]);
    selectedLoanOptionRef.current = loanOptions[0];
    showQuote(lastQuoteResponse.current, 0);
  }

  function showQuote(quoteResponse, index) {
    const loanDurationWiseDetailsArr =
      lastQuoteResponse.current.loanDurationWiseDetails;
    const loanDurationWiseDetails = loanDurationWiseDetailsArr[index];
    setRequestQuoteViewModel(
      mapToRequestQuoteViewModel(
        quoteResponse.amountRequested,
        loanDurationWiseDetails
      )
    );
  }

  const handleLoanOptionChange = (option) => {
    showQuote(lastQuoteResponse.current, option.id);
    setSelectedLoanOption(option);
    selectedLoanOptionRef.current = option;
    setTermsCheckboxState(false);
    termsCheckboxRef.current = false;
  };

  function getSelectedLoanQuoteRefId() {
    const loanOptionIndex = selectedLoanOptionRef.current.id;
    const loanDurationWiseDetailsArr =
      lastQuoteResponse.current.loanDurationWiseDetails;
    return loanDurationWiseDetailsArr[loanOptionIndex].quoteRefId;
  }

  function getSelectedLoanTenure() {
    const loanOptionIndex = selectedLoanOptionRef.current.id;
    const loanDurationWiseDetailsArr =
      lastQuoteResponse.current.loanDurationWiseDetails;
    return loanDurationWiseDetailsArr[loanOptionIndex].installmments.length;
  }

  function submitOrder() {
    const request = {
      employeeId: employeeRef.current.employeeId,
      companyId: employeeRef.current.companyId,
      loanDurationInDays: Number(daysRemaining),
      amountRequested: Number(amountRef.current),
      quoteRefId: getSelectedLoanQuoteRefId(),
      tenureInMonths: getSelectedLoanTenure(),
    };
    post(createUrl("/api/orders"), request)
      .then((response) => {
        if (response.data.status === "success") {
          fetchDetails();
          amountRef.current = 0;
          updateStateToRequestQuote();
        }
      })
      .catch(function (error) {
        let btnState = Object.assign({}, submitButtonState);
        btnState.visible = true;
        btnState.enable = true;
        btnState.loader = false;
        setSubmitButtonState(btnState);
        if (error.response.data) {
          handleError(
            error.response.data["errorTitle"],
            error.response.data["errorMessage"]
          );
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  function handleError(title, message) {
    let dialogModel = new DialogModel();
    dialogModel.show = true;
    dialogModel.title = title;
    dialogModel.body = message;
    let positiveBtn = new DialogButton();
    positiveBtn.label = "Dismiss";
    positiveBtn.onClick = () => {
      let closeModel = new DialogModel();
      closeModel.show = false;
      setDialog(closeModel);
    };
    dialogModel.positiveBtn = positiveBtn;
    dialogModel.onCrossClick = () => {
      let closeModel = new DialogModel();
      closeModel.show = false;
      setDialog(closeModel);
    };
    setDialog(dialogModel);
  }

  const onLogOutClicked = () => {
    logoutUseCase(onLogoutSuccess);
  };

  const onLogoutSuccess = () => {
    setStoredJwt(null);
    navigate("../login");
  };

  return (
    <div>
      <MainView
        employee={employee}
        onDaysChanged={onDaysChanged}
        onAmountChanged={onAmountChanged}
        submitButton={submitButtonState}
        orders={ordersState}
        dialogModel={dialog}
        requestQuoteViewModel={requestQuoteViewModel}
        onTermsCheckBoxChanged={onTermsCheckBoxChanged}
        termsCheckboxChecked={termsCheckboxState}
        onStartKycClicked={onStartKycClicked}
        showKycDialog={showKycDialog}
        onSubmitKycClicked={onSubmitKycClicked}
        onCloseKycDialogClicked={onCloseKycDialogClicked}
        isSanctionLetterOpen={isSanctionLetterOpen}
        isLoanAgreementOpen={isLoanAgreementOpen}
        onClickShowSanctionLetter={onClickShowSanctionLetter}
        onClickShowLoanAgreement={onClickShowLoanAgreement}
        onSanctionLetterClosed={onSanctionLetterClosed}
        onLoanAgreementClosed={onLoanAgreementClosed}
        onLogOutClicked={onLogOutClicked}
        loanOptions={loanOptions}
        selectedLoanOption={selectedLoanOption}
        handleLoanOptionChange={handleLoanOptionChange}
      />
    </div>
  );
};
