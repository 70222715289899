import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export const CustomDialog = ({ dialogModel }) => {
  return (
    <MDBModal show={dialogModel.show} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{dialogModel.title}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={
                dialogModel.onCrossClick != null && dialogModel.onCrossClick
              }
            />
          </MDBModalHeader>
          <MDBModalBody>{dialogModel.body}</MDBModalBody>

          <MDBModalFooter>
            {dialogModel.negativeBtn && (
              <MDBBtn
                color="secondary"
                noRipple={true}
                onClick={
                  dialogModel.negativeBtn != null &&
                  dialogModel.negativeBtn.onClick
                }
              >
                {dialogModel.negativeBtn.label}
              </MDBBtn>
            )}
            {dialogModel.positiveBtn && (
              <MDBBtn
                noRipple={true}
                onClick={
                  dialogModel.positiveBtn != null &&
                  dialogModel.positiveBtn.onClick
                }
              >
                {dialogModel.positiveBtn.label}
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
