import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "mdb-react-ui-kit";
import { KycStatus } from "../shared/model/KycStatus";
import { createUrl, get } from "../../network/AxiosInterceptor";
import { useNavigate } from "react-router-dom";

export const KycEmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    get(createUrl(`/api/employees?kycstatus=${KycStatus.IN_PROCESS}&limit=10`))
      .then((response) => {
        setEmployees(response.data.map(mapEmployees));
      })
      .catch((error) => console.log(error));

    function mapEmployees(employee) {
      return {
        id: employee.id,
        name: employee.fullName,
        company: employee.companyName,
        kycStatus: employee.kycStatus,
      };
    }
  }, []);

  const handleButtonClick = (id) => {
    navigate(`/admin/kyc/${id}`);
  };

  const handleEmployeeClicked = (event, id) => {
    event.preventDefault();
    navigate(`/admin/employee/${id}`);
  };

  return (
    <MDBTable striped bordered>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Company</th>
          <th>KYC Status</th>
          <th>Actions</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {employees.map((employee) => (
          <tr key={employee.id}>
            <td>{employee.id}</td>
            <td>
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={(event) => handleEmployeeClicked(event, employee.id)}
              >
                {employee.name}
              </span>
            </td>
            <td>{employee.company}</td>
            <td>{employee.kycStatus}</td>
            <td>
              <MDBBtn
                color="primary"
                onClick={() => handleButtonClick(employee.id)}
                noRipple={true}
              >
                View/Edit KYC
              </MDBBtn>
            </td>
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
  );
};
