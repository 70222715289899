import { Data } from "dataclass";
import { KycStatus } from "../shared/model/KycStatus";

export class Employee extends Data {
  name: string = "";
  companyName: string = "";
  email: string = "";
  companyEmployeeId: string = "";
  employeeId: string = "";
  companyId: string = "";
  phoneNumber: string = "";
  pan: string = "";
  address: string = "";
  aadhar: string = "";
  kycStatus: string = KycStatus.PENDING;
  eligibleAmount: number = 0;
}
